import { Box, Text } from "@chakra-ui/react";

import { HeroItemStats } from "../../../types";
import { extractPercentage } from "../../../core/utils";
import SentimentMeter from "./SentimentMeter";

const iotaHarcodedWeight = 0.55; // We need to define a % (55% in this case) of IOTA weight representation in the IOTA EVM market

interface SentimentInterface {
  textLabel: string;
  color: string;
}
const sentimentArray: SentimentInterface[] = [
  { textLabel: "Blood Bath", color: "#a90000" }, // Blood Red
  { textLabel: "Panic", color: "#a92e00" }, // Red Orange
  { textLabel: "Worried", color: "#c04e0c" }, // Orange
  { textLabel: "Unstable", color: "#8e8b09" }, // Yellow
  { textLabel: "Healthy", color: "#5f8708" }, // Green Yellow
  { textLabel: "Optimistic", color: "#389537" }, // Green
  { textLabel: "Bullish", color: "green.700" }, // Dark Green
  { textLabel: "I'm Rich!", color: "green.900" }, // Really Dark Green
];

export function GetSentimentContent(
  itemsData: HeroItemStats[],
  isMobile: boolean
) {
  const { sentimentValue, currentSentiment } = getSentimentValue(itemsData);

  return (
    <Box>
      <Text
        fontSize={isMobile ? "4xl" : "6xl"}
        fontWeight={"extrabold"}
        textColor={currentSentiment.color}
        textAlign={"center"}
        paddingTop={isMobile ? 3 : 12}
        paddingBottom={isMobile ? 0.5 : 5}
      >
        <Box
          border="4px"
          borderColor={"#c1b0ca"}
          rounded={"full"}
          width={isMobile ? "110px" : "180px"}
          display={"inline-block"}
        >
          {!Number.isNaN(sentimentValue) ? sentimentValue : "..."}
        </Box>
      </Text>
      <SentimentMeter value={sentimentValue} />
      <Text
        fontSize={isMobile ? "l" : "xl"}
        fontWeight={"bold"}
        textAlign={"center"}
        p={3}
      >
        {!Number.isNaN(sentimentValue) ? currentSentiment.textLabel : ""}
      </Text>
    </Box>
  );
}

function getSentimentValue(itemsData: HeroItemStats[]) {
  let totalMarketcap = 0;
  let ignoredIotaIndex;
  let indexsToIgnore = []; // This array is for new assets with NaN 2Hours%
  let assetWeight: number[] = [];

  // Weighted Ponderations
  let weightedOneHourPonderated = 0;
  let weightedTwoHoursPonderated = 0;
  let weightedOneDayPonderated = 0;

  // Relevating MarketCap
  for (let i = 0; i < itemsData.length; i++) {
    if (itemsData[i].Symbol === "IOTA") {
      ignoredIotaIndex = i;
      continue; // IGNORE IOTA for now
    }
    if (itemsData[i].PriceChange.Day.ChangeString !== "-")
      totalMarketcap += itemsData[i].MarketCapNumber;
    else indexsToIgnore.push(i);
  }
  totalMarketcap += totalMarketcap * iotaHarcodedWeight; // We add % more in representation of IOTA

  // Assets Weight Calculation
  for (let i = 0; i < itemsData.length; i++) {
    if (i === ignoredIotaIndex) {
      assetWeight.push(iotaHarcodedWeight);
      continue;
    }
    if (indexsToIgnore.includes(i)) continue;
    assetWeight.push(itemsData[i].MarketCapNumber / totalMarketcap);
  }

  // Obtaining weighted ponderations
  // 1 HOUR %
  for (let i = 0; i < assetWeight.length; i++) {
    if (indexsToIgnore.includes(i)) continue;
    weightedOneHourPonderated +=
      assetWeight[i] *
      extractPercentage(itemsData[i].PriceChange.Hour.ChangeString);
  }

  // 2 HOURS %
  for (let i = 0; i < assetWeight.length; i++) {
    if (indexsToIgnore.includes(i)) continue;
    weightedTwoHoursPonderated +=
      assetWeight[i] *
      extractPercentage(itemsData[i].PriceChange.TwoHours.ChangeString);
  }

  // 24 HOURS %
  for (let i = 0; i < assetWeight.length; i++) {
    if (indexsToIgnore.includes(i)) continue;
    weightedOneDayPonderated +=
      assetWeight[i] *
      extractPercentage(itemsData[i].PriceChange.Day.ChangeString);
  }

  // Ponderations: 24hs has 65% importance, 2hrs has 20% importance, 1hr has 15% importance
  let weightedPonderatedGeneral =
    weightedOneHourPonderated * 0.15 +
    weightedTwoHoursPonderated * 0.25 +
    weightedOneDayPonderated * 0.6;

  // Function that quantifies from 1 to 100 the ponderation result obtained - TODO
  let sentimentValueRaw: number;
  if (weightedPonderatedGeneral <= 0) {
    sentimentValueRaw = functionLowerThanZero(weightedPonderatedGeneral);
  } else sentimentValueRaw = functionGreaterThanZero(weightedPonderatedGeneral);
  let sentimentValue = Math.round(sentimentValueRaw);

  if (sentimentValue < 0) sentimentValue = 0;
  if (sentimentValue > 100) sentimentValue = 100;

  let currentSentiment: SentimentInterface = sentimentArray[5];
  if (sentimentValue < 16) currentSentiment = sentimentArray[0];
  if (sentimentValue >= 16 && sentimentValue < 28)
    currentSentiment = sentimentArray[1];
  if (sentimentValue >= 28 && sentimentValue < 44)
    currentSentiment = sentimentArray[2];
  if (sentimentValue >= 44 && sentimentValue < 55)
    currentSentiment = sentimentArray[3];
  if (sentimentValue >= 55 && sentimentValue < 69)
    currentSentiment = sentimentArray[4];
  if (sentimentValue >= 69 && sentimentValue < 83)
    currentSentiment = sentimentArray[5];
  if (sentimentValue >= 83 && sentimentValue < 98)
    currentSentiment = sentimentArray[6];
  if (sentimentValue >= 98) currentSentiment = sentimentArray[7];

  return { sentimentValue, currentSentiment };
}

// This function was calculated with 5 points in the site https://www.dcode.fr/function-equation-finder
// \frac{523x^{3}}{1680000}+\frac{377x^{2}}{8000}+\frac{21337x}{8400}+63
function functionLowerThanZero(x: number) {
  const term1 = (523 * Math.pow(x, 3)) / 1680000;
  const term2 = (377 * Math.pow(x, 2)) / 8000;
  const term3 = (21337 * x) / 8400;
  const term4 = 63;

  return term1 + term2 + term3 + term4;
}

// This function was calculated testing numbers in the Horizontal Asymptote in a graph viewer
// y=\frac{39x}{\sqrt{x^{2}+300}}+63
function functionGreaterThanZero(x: number) {
  const denominator = Math.sqrt(Math.pow(x, 2) + 300);
  const term1 = (39 * x) / denominator;
  const term2 = 63;

  return term1 + term2;
}
