import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Image,
  Divider,
  Link as ChakraLink,
  HStack,
  Center,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { backgroundGeneralColor } from "../customTheme";
import ContactForm from "./partials/ContactForm";

export default function ContactUsPage() {
  return (
    <Box bg={backgroundGeneralColor}>
      <Container maxW="container.xl">
        <Stack as={Box} textAlign={"center"} py={{ base: 20, md: 36 }}>
          <Heading
            color={"gray.800"}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Contact Us
          </Heading>
          <Text
            fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
            color={"gray.800"}
            paddingTop={6}
            paddingBottom={2}
            align={"left"}
            maxW="1000px"
            alignSelf={"center"}
          >
            To contact us please fill out the form. We will reply to you
            shortly.
          </Text>
          <ContactForm />

          <Center>
            <Box>
              <Heading
                color={"gray.800"}
                paddingTop={20}
                fontWeight={600}
                textAlign={"left"}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                lineHeight={"110%"}
              >
                About Us:
              </Heading>
              <Text
                fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
                color={"gray.800"}
                paddingTop={2}
                paddingStart={3}
                paddingBottom={2}
                align={"left"}
                maxW="1000px"
                alignSelf={"center"}
              >
                <b>IotaCap</b> has been developed by <i>Gustavo Belbruno</i> (
                <i>
                  <u>
                    <a href="https://x.com/gustavobelbruno">@gustavobelbruno</a>
                  </u>
                </i>{" "}
                on <i>X</i>).
              </Text>

              <Heading
                color={"gray.800"}
                paddingTop={20}
                fontWeight={600}
                textAlign={"left"}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                lineHeight={"110%"}
              >
                <u>
                  <a href="/disclaimer">IotaCap Disclaimer Page</a>
                </u>
              </Heading>
            </Box>
          </Center>
        </Stack>
      </Container>
    </Box>
  );
}
