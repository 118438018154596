import { Box, Tab, TabList, Tabs, useMediaQuery } from "@chakra-ui/react";
import {
  AreaChart,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  ResponsiveContainer,
  Area,
} from "recharts";
import { format } from "date-fns";
import { pricePrettier, pricePrettierYAxis } from "../../core/utils";
import { useEffect, useState } from "react";
import { getPriceRangeDbDataFetcher } from "../../core/RpcDataFetcher";
import { loadingChart } from "../../types";
import { primaryColor } from "../../customTheme";

export default function PriceAssetChart(assetAddress: string) {
  const [isMobile] = useMediaQuery("(max-width: 870px)");
  let dateFormat = "EEE d";

  const [selectedTab, setSelectedTab] = useState(0);
  const [dateFormatState, setDateFormatState] = useState("EEE d");
  const [priceRangeDataState, setPriceRangeDataState] = useState(loadingChart);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const changeGraph = async () => {
      try {
        setIsLoading(false);

        let rawDataPrice: PriceData[] = [];
        switch (selectedTab) {
          case 0: // 1day
            setIsLoading(true);
            setDateFormatState("HH:mm");
            rawDataPrice = await getPriceRangeDbDataFetcher(assetAddress, 1, 3);
            setIsLoading(false);
            break;
          case 1: // 7days
            setIsLoading(true);
            setDateFormatState("EEE d");
            rawDataPrice = await getPriceRangeDbDataFetcher(
              assetAddress,
              7,
              30
            );
            setIsLoading(false);
            break;
          case 2: // 1 month
            setIsLoading(true);
            setDateFormatState("EEE d");
            rawDataPrice = await getPriceRangeDbDataFetcher(
              assetAddress,
              31,
              120
            );

            setIsLoading(false);
            break;
          case 3: // 3 months
            setIsLoading(true);
            setDateFormatState("EEE d");
            rawDataPrice = await getPriceRangeDbDataFetcher(
              assetAddress,
              90,
              360
            );

            setIsLoading(false);
            break;
          case 4: // 1 year
            setIsLoading(true);
            setDateFormatState("EEE d");
            rawDataPrice = await getPriceRangeDbDataFetcher(
              assetAddress,
              365,
              1440
            );

            setIsLoading(false);
            break;
          default:
            break;
        }

        //Delete all zeros and set
        setPriceRangeDataState(replaceZeroPrices(rawDataPrice));
      } catch (error) {}
    };
    changeGraph();
  }, [selectedTab]);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const firstPrice = priceRangeDataState.length
    ? priceRangeDataState[0].price_usd
    : null;
  const lastPrice = priceRangeDataState.length
    ? priceRangeDataState[priceRangeDataState.length - 1].price_usd
    : null;
  let isPositiveGain = true;
  if (firstPrice != null && lastPrice != null) {
    isPositiveGain = lastPrice < firstPrice ? false : true;
  }
  const lineColor = isPositiveGain ? "#00612c" : "#c00000";
  const areaColor = isPositiveGain ? "#b9dfbf" : "#e2a1a1";

  return (
    <Box width={"full"} height={"500px"} marginStart={3} marginBottom={10}>
      <Box textColor={"black"} textAlign={"right"}>
        <Tabs
          variant="enclosed"
          align="end"
          marginEnd={12}
          onChange={(index) => handleTabChange(index)}
          colorScheme={"purple"}
        >
          <TabList>
            <Tab>1D</Tab>
            <Tab>7D</Tab>
            <Tab>1M</Tab>
            <Tab>3M</Tab>
            <Tab>1Y</Tab>
            <Tab isDisabled={true}>All</Tab>
          </TabList>
        </Tabs>
      </Box>

      <ResponsiveContainer>
        <AreaChart data={priceRangeDataState} syncId="ChartPrice">
          <CartesianGrid strokeDasharray="5 10000" />
          <XAxis
            dataKey="timestamp"
            tick={{ fill: "#4d4d4d" }}
            tickFormatter={(timestamp) =>
              `   ${format(timestamp * 1000, dateFormatState)}   `
            }
          />
          <YAxis
            orientation="right"
            domain={["auto", "auto"]}
            tick={{ fill: "#4d4d4d" }}
            fontSize={"15"}
            tickFormatter={(value) => `$${pricePrettierYAxis(value, 2)}`}
          />
          <Tooltip
            formatter={(value) => [`$${pricePrettier(Number(value))}`]}
            labelFormatter={(value) =>
              `Date: ${format(value * 1000, "HH:mm -- d MMM y z")}`
            }
          />

          <Area
            type="monotone"
            dataKey="price_usd"
            stroke={lineColor}
            fill={areaColor}
            strokeWidth={1.4}
            animationEasing="ease-in-out"
            animationDuration={60}
          />
          <Brush
            height={22}
            fill="#BFBFBF"
            startIndex={0}
            tickFormatter={(_, index) =>
              `   ${format(
                priceRangeDataState[index].timestamp * 1000,
                dateFormat
              )}   `
            }
          />

          {lastPrice !== null && (
            <ReferenceLine
              y={lastPrice}
              stroke="black"
              strokeDasharray="5 5"
              label={{
                value: `Last: $${pricePrettierYAxis(lastPrice, 4)}`,
                position: "right",
                fill: "black",
                fontSize: 7,
                fontWeight: "bold",
              }}
            />
          )}

          {isLoading && (
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              fill={primaryColor}
              fontSize={isMobile ? "22" : "30"}
            >
              Loading Chart data...
            </text>
          )}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}

type PriceData = {
  price_usd: number;
  timestamp: number;
};

function replaceZeroPrices(data: PriceData[]): PriceData[] {
  if (data.length === 0) {
    return data;
  }

  let previousPrice = data[0].price_usd;

  return data.map((entry, index) => {
    if (entry.price_usd === 0) {
      entry.price_usd = previousPrice;
    } else {
      previousPrice = entry.price_usd;
    }
    return entry;
  });
}
