import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Image,
  Divider,
  Link as ChakraLink,
  HStack,
  Center,
  ListItem,
  UnorderedList,
  OrderedList,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { backgroundGeneralColor } from "../customTheme";

export default function DisclaimerPage() {
  return (
    <Box bg={backgroundGeneralColor}>
      <Container maxW="container.xl">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            color={"gray.800"}
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Disclaimer
          </Heading>
          <Text
            fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
            color={"gray.800"}
            align={"left"}
            maxW="1000px"
            fontFamily={"sans-serif"}
            alignSelf={"center"}
          >
            <OrderedList spacing={"10"}>
              <ListItem>
                The information provided on this website does not constitute
                investment advice, financial advice, trading advice, or any
                other sort of advice and you should not treat any of the
                website's content as such. <i>IotaCap</i> does not recommend
                that any cryptocurrency should be bought, sold, or held by you.
                Do conduct your own due diligence and consult your financial
                advisor before making any investment decisions.
              </ListItem>
              <ListItem>
                <i>IotaCap</i> will strive to ensure accuracy of information
                listed on this website although it will not hold any
                responsibility for any missing or wrong information.{" "}
                <i>IotaCap</i> provides all information as is. You understand
                that you are using any and all information available here at
                your own risk.
              </ListItem>
              <ListItem>
                The appearance of third party advertisements and hyperlinks on{" "}
                <i>IotaCap</i> does not constitute an endorsement, guarantee,
                warranty, or recommendation by <i>IotaCap</i>. Do conduct your
                own due diligence before deciding to use any third party
                services.
              </ListItem>
              <ListItem>
                <i>IotaCap</i> may receive compensation for affiliate links.
                This compensation may be in the form of money or services and
                could exist without any action from a site visitor. Should you
                perform activities in relation to an affiliate link, it is
                understood that some form of compensation might be made to{" "}
                <i>IotaCap</i>. For example, if you click on an affiliate link,
                and sign up and trade on an exchange, <i>IotaCap</i> may receive
                compensation. Each affiliate link is clearly marked with an icon
                next to it.
              </ListItem>
              Affiliate Disclosure
              <ListItem>
                The information provided by <i>IotaCap</i> on new token launches
                is for general information purposes only. We make no
                representation or warranty of any kind, express or implied,
                regarding the accuracy, reliability or completeness of any
                information on the new token launches. The information provided
                also does not constitute an endorsement, invitation, or
                recommendation to invest or deal in such tokens. Digital assets
                are volatile. Users should assess their financial situation,
                risk tolerance, and do their own research. <i>IotaCap</i> shall
                not have any liability to you for any loss or damage of any kind
                incurred as a result of the use of our platform or reliance on
                any information provided on the platform.
              </ListItem>
            </OrderedList>
          </Text>
        </Stack>
      </Container>
    </Box>
  );
}
