import { WhitelistTokenData } from "./types";

export const shimmercapApiUrl = `https://shimmercap-api-git-prod-iotacap-gustbels-projects.vercel.app/api/`;

export const explorerUrl = `https://explorer.evm.iota.org/address/`;

export const fastMode = true; // In this mode an 1 minute updated json is waiting in a database for obtaint all the data faster
// Is used in Hero (for get fas all the asset list) and in Asset Page for get fast data too

export const whitelistTokenData: WhitelistTokenData[] = [
  {
    Address: "0x1111111111111111111111111111111111111111",
    ImageURL: "/assetLogos/iota.svg",
    Name: "IOTA",
    Symbol: "IOTA",
    Details: {
      Description:
        "The <b>IOTA Token</b> is the main and most important asset of the <i>IOTA Cryptocurrency Project</i>.<br><br>The <b>IOTA Project</b> is an Open-Source Distributed Ledger (Cryptocurrency), being a significant vector within the crypto ecosystem. Created in <i>Germany</i> in 2017 by <i>Dominik Schiener</i>, <i>David Sønstebø</i>, <i>Sergey Ivancheglo</i>, and <i>Dr. Serguei Popov</i>, its <b>Main Layer (L1)</b> uses <b>Directed Acyclic Graph (DAG)</b> technology instead of the traditional <i>blockchain</i>. It is one of the first projects to use this technology in <i>blockchain</i>.<br><br>Initially, the network was focused on providing secure communications and payments between machines on the <i>Internet of Things</i>. Today, the project is much more ambitious, as it not only maintains its initial foundations but is also compatible with a <b>Layer2</b> officially launched by the project in 2024. This <i>Layer2</i> (which showcases its full potential with an <b>Ethereum Virtual Machine</b> embedded, called <b>IOTA EVM</b>) allows <i>IOTA</i> users to create and interact with smart contracts, enabling a wide range of applications and services now running natively on the project.<br><br><b>IOTA L2 Layer</b> indirectly makes the project richer and more diverse, aligns with global crypto trends, and opens the door to numerous projects and opportunities, including <i>DeFi</i>, <i>RWA</i>, <i>Tokenomics</i>, <i>NFTs</i>, <i>Metaverse</i>, among others <i>DApps</i>.<br><br><b>IOTA</b> is listed by the <b>world's leading cryptocurrency exchanges</b>, including <i>Binance</i>, <i>OKX</i>, <i>Bitfinex</i>, <i>Upbit</i>, <i>Gate.io</i>, <i>Bitpanda</i>, <i>HTX</i>, among others.",
      OfficialLinks: {
        website: "https://www.iota.org/",
        tokenPage:
          "https://wiki.iota.org/get-started/introduction/iota/iota-token/",
        github: "https://github.com/iotaledger",
      },
      SocialLinks: {
        xTwitter: "https://x.com/iota/",
        discord: "https://discord.iota.org/",
        reddit: "https://www.reddit.com/r/Iota/",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["USDC.e"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x8f9a72d8f56479903ae386849e290d49389e41f9",
        },
        {
          exchange: "MagicSea",
          assets: ["wETH"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xa4a8ef658ae0dbcca558e0b2dbd9e51925180d32",
        },
        {
          exchange: "MagicSea",
          assets: ["MLUM"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xb895f2be2347c244f202ca4b86db3a6722b10756",
        },
        {
          exchange: "MagicSea",
          assets: ["wETH"],
          type: "DEX Pool LB",
          poolType: "ms_lb",
          address: "0xbac6c7808c453e988163283eb71e876cb325a3ee",
        },
        {
          exchange: "MagicSea",
          assets: ["LUM"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xa687eddac2648337492f37a182ca555e7e62b72a",
        },
        {
          exchange: "MagicSea",
          assets: ["wSMR"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x1c95009b4312bfbfbf340ef3224f30e16b774533",
        },
        {
          exchange: "MagicSea",
          assets: ["RUST"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x932059511253f55b9c288f9a9c4e54561f0fd05c",
        },
      ],
    },
  },
  {
    Address: "0x160345fc359604fc6e70e3c5facbde5f7a9342d8",
    ImageURL: "/assetLogos/weth_lz.png",
    Name: "Wrapped Ethereum",
    Symbol: "WETH",
    Details: {
      Description:
        "<b>WETH</b> is a <i>LayerZero Bridged Token</i> of <i><b>ETH</b></i> Asset that we can find on the <i>Ethereum Mainnet Network</i>.<br><br><b>ETH</b> or <b>Ethereum</b> is the <b><i>Native Asset</i></b> of the <i>Ethereum Network</i>.<br><br>The Bridge of this Asset is carried out by the company <b>LayerZero</b>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text).<br>The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.",
      OfficialLinks: {
        website: "https://ethereum.org/",
        tokenPage: "https://ethereum.org/en/whitepaper/",
        github: "https://github.com/ethereum",
      },
      SocialLinks: {
        xTwitter: "https://x.com/ethereum",
        discord: "https://discord.com/invite/ethereum-org",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xa4a8ef658ae0dbcca558e0b2dbd9e51925180d32",
        },
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool LB",
          poolType: "ms_lb",
          address: "0xbac6c7808c453e988163283eb71e876cb325a3ee",
        },
      ],
    },
  },
  {
    Address: "0x009bce26c6812c559aff5eb5769c8758701673d2",
    ImageURL: "/assetLogos/wsmr.svg",
    Name: "Wrapped Shimmer",
    Symbol: "wSMR",
    Details: {
      Description:
        "<b>WSMR</b> is a <i>LayerZero Bridged Token</i> of <i><b>SMR</b></i> Asset that we can find on the <i>Shimmer Network</i> and <i>ShimmerEVM Network</i>.<br><br><b>SMR</b> or <b>Shimmer</b> is the <b><i>Native Asset</i></b> of the <i>Shimmer Network</i>.<br><br>The Bridge of this Asset is carried out by the company <b>LayerZero</b>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text).<br>The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.",
      OfficialLinks: {
        website: "https://shimmer.network/",
        tokenPage: "https://shimmer.network/token",
        github: "https://github.com/iotaledger",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/shimmernet/",
        discord: "https://discord.shimmer.network/",
        reddit: "https://www.reddit.com/r/shimmer/",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x1c95009b4312bfbfbf340ef3224f30e16b774533",
        },
      ],
    },
  },
  {
    Address: "0xc1b8045a6ef2934cf0f78b0dbd489969fa9be7e4",
    ImageURL: "/assetLogos/usdt_lz.png",
    Name: "Tether",
    Symbol: "USDT",
    Details: {
      Description:
        "<b>USDT</b> is a <i>LayerZero Bridged Token</i> of the <i><b>USDT</b> Token</i> that we can find on the <i>Ethereum Mainnet Network</i>.<br><br><b>USDT</b> or <b>Tether</b> is a Stablecoin that is pegged to the value of <b>USD Dollar</b>. The PEG of the Token in USDT is centrally controlled by a series of companies and obtained by maintaining reserves in the physical world in a sum that equals the number of USDT circulating in the Ethereum Network.<br><br>The Bridge of this Asset is carried out by the company <b>LayerZero</b>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text).<br>The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.",
      OfficialLinks: {
        website: "https://tether.to/",
        tokenPage: "hhttps://tether.to/en/transparency/?tab=usdt",
        github: "https://github.com/LayerZero-Labs/LayerZero-v2",
      },
      SocialLinks: {
        xTwitter: "https://x.com/Tether_to/",
        telegram: "https://t.me/OfficialTether",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["USDC.e"],
          type: "DEX Pool LB",
          poolType: "ms_lb",
          address: "0xe919092cc7cbd2097ae3158f72da484ac813b74b",
        },
      ],
    },
  },
  {
    Address: "0xfbda5f676cb37624f28265a144a48b0d6e87d3b6",
    ImageURL: "/assetLogos/usdc_lz.png",
    Name: "USD Coin",
    Symbol: "USDC.e",
    Details: {
      Description:
        "<b>USDC.e</b> is a <i>LayerZero Bridged Token</i> of the <i><b>USDC</b> Token</i> that we can find on the <i>Ethereum Mainnet Network</i>.<br><br><b>USDC</b> is a stablecoin that is pegged to the value of <b>USD Dollar</b>. The PEG of the Token in USDC is centrally controlled by the <i>Centre Consortium</i> and obtained by maintaining reserves in the physical world in a sum that equals the number of USDC circulating in the Ethereum Network.<br><br>The Bridge of this Asset is carried out by the company <b>LayerZero</b>, with a protocol and contracts created by them that connects more than 50 Blockchains (as of writing this text).<br>The <i>LayerZero</i> protocol relies on immutable on-chain endpoints, a configurable Security Stack, and a permissionless set of Executors to transfer censorship-resistant messages between chains.",
      OfficialLinks: {
        website: "https://www.usdc.com/",
        tokenPage:
          "https://f.hubspotusercontent30.net/hubfs/9304636/PDF/centre-whitepaper.pdf",
        github: "https://github.com/circlefin/stablecoin-evm",
      },
      SocialLinks: {
        xTwitter: "https://x.com/circle",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x8f9a72d8f56479903ae386849e290d49389e41f9",
        },
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool LB",
          poolType: "ms_lb",
          address: "0xa86d3169d5cccdc224637adad34f4f1be174000c",
        },
        {
          exchange: "MagicSea",
          assets: ["USDT"],
          type: "DEX Pool LB",
          poolType: "ms_lb",
          address: "0xe919092cc7cbd2097ae3158f72da484ac813b74b",
        },
      ],
    },
  },
  {
    Address: "0x30a1398cF11cb9E852E5d80aCe7FFd5ec271169E",
    ImageURL: "/assetLogos/fomo.png",
    Name: "FOMOFox",
    Symbol: "FOMO",
    Details: {
      Description:
        "<b>FOMO</b> or <b>FOMOFox</b> is a <i>Memecoin</i> Token, it’s emblem figure is a <b>cartoon Fox</b>.<br><br>The project self-identifies as the <i>'The 1st storytelling memecoin on IOTA EVM'</i>. This approach establishes a storyline centered around the main character of this project. As market events progress, the decisions made by the <i>fox</i> in its narrative will be influenced by the challenges and situations encountered in the <i>IOTA Market</i>.<br><br>The project plans <b>'Community Incentives'</b> for members of the <i>Iota Community</i>, among which we can find on their website, they include: <i>Airdrops</i>, <i>Raffles</i> and <i>Campaigns</i> (details of these incentives can be found in the project <i>Whitepaper</i>). According to the <i>Project Tokenomics</i>, 20% of the <i>Total Supply</i> will be allocated for these <i>Community Incentives.</i><br><br>On their website we can find an interesting <b>Educational section</b>, from there, we have access to various <b>useful articles</b> (written by the authors of the project) dedicated to <i>general crypto education</i>, <i>understanding the principles of the IOTA Protocol</i>, and <i>ensuring user security when handling crypto assets</i>. (Education Section: <u>https://fomofox.info/education/</u>).<br><i><b>IotaCap</b></i> recommends users take a look at the articles and encourages other projects to join this healthy practice.<br><br><b>FOMO Token</b> (and <b>FOMOFox Project</b>) has been developed by <b>Martin</b> (aka <i>LECTOR</i> and <i>@LECTORdesign</i> in X) as the <i>Principal Project Manager</i> of the project, and <b>Tobi</b> (aka <i>le_erdnuss</i> and <i>@Erdnuss90</i> in X) as <i>AI-generated Content Artist</i>.",
      OfficialLinks: {
        website: "https://fomofox.info/",
        tokenPage:
          "https://fomofox.info/wp-content/uploads/2024/07/Whitepaper_FOMOFox.pdf",
      },
      SocialLinks: {
        xTwitter: "https://x.com/FOMO_Fox",
        discord: "https://discord.com/invite/hHyX6EhSWg",
        telegram: "https://t.me/FOMOFox",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x7ceb96304fbea063d6c461a57ce496213bebe535",
        },
      ],
    },
  },
  {
    Address: "0xA87666b0Cb3631c49f96CBf1e6A52EBac79A6143",
    ImageURL: "/assetLogos/mlum.svg",
    Name: "MagicLum",
    Symbol: "MLUM",
    Details: {
      Description: `<b>MLUM</b> is the Governance Token of the <b>MagicSea Platform</b>. <br><br><b>MagicSea</b> (formerly known as <i>MagicSea</i>) is a Multichain platform that focuses primarily on its DEX product (<i>UniswapV2</i> fork), being, as of this writing, the DEX with the most liquidity on the <i>IOTA EVM Network</i> and the <i>ShimmerEVM Network</i> (according to the amount of token liquidity in their main contracts). <br><i>MagicSea</i> also offers other products such as the ability to Stake and Farm certain selected tokens, an NFT Marketplace, a token Fairlaunch platform, among other products. <br><br>The <b>MLUM Token</b> offers several features for the User, among the most notable is that holding <b>MLUM</b> gives the user <i>Voting</i> possibilities in the <i>MagicSea DAO</i>, and also, <b>MagicLum</b> holders receive rewards via selected <i>staking pools</i>.<br><br><i>MagicSea</i> offers a conversion mechanism from the reward token <b>LUM</b> to <b>MLUM</b> in the <i>ShimmerEVM Network</i>, this conversion is made by a contract called <i>Booster</i>.<br><br><b>MLUM</b> was originally launched on the <i>ShimmerEVM Network</i>, and the platform offers its own <i>Bridge</i> (via <i>LayerZero</i>) so that users can have this token on the <i>IOTA EVM Network</i>. Its Max TotalSupply is defined by contract at 1M tokens.`,
      OfficialLinks: {
        website: "https://app.magicsea.finance/",
        tokenPage: "https://docs.magicsea.finance/tokenomics/magic-lum-token",
      },
      SocialLinks: {
        xTwitter: "https://x.com/MagicSeaDEX",
        discord: "https://discord.gg/zKgPXY6K97",
        telegram: "https://t.me/tangleseaglobal",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xb895f2be2347c244f202ca4b86db3a6722b10756",
        },
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool LB",
          poolType: "ms_lb",
          address: "0x34fc33940074cb8f80537890ad0be06771687bdc",
        },
      ],
    },
  },
  {
    Address: "0x3915b5a673ee6cf126d1042f637bd10b9931ad08",
    ImageURL: "/assetLogos/rust.png",
    Name: "Rusty Robot Country Club",
    Symbol: "RUST",
    Details: {
      Description:
        "<b>RUST</b> is the main utility token of the <b>Rusty Robot Country Club</b> project.<br><b>Rusty Robot Country Club</b> is an NFT collection which aims to create a (citing the author) `collaborative, creative community` among its owners. Users become `members` by having the owner of a <i>Rusty Robot Country Club NFT</i>.<br><br><b>RUST Token</b> holders have more benefits in <i>Rusty Robot Country Club</i>.<br><br>It is not clear from the information obtained by official media who the developers of <i>Rusty Robot Country Club</i> are, however, according to the <i>Tangleverse Project</i> website (<u>www.tangleverse.io</u>) the developers of <i>Rusty Robot Country Club</i> are: <b>Pathin</b> (@ThePathin on X), <b>Andy</b>, <b>QQLA-17</b>, <b>Addommor</b>, among others. We cannot fully echo this information since, again, it is not obtained from official media but from an indirect source of information.<br><br><b>RUST Token</b> was originally launched on the <i>ShimmerEVM Network</i>, and the platform offers its own <i>Bridge</i> (via <i>LayerZero</i>) so that users can have this token on the <i>IOTA EVM Network</i>.<br>Its Max TotalSupply is defined by contract at 2000M tokens.",
      OfficialLinks: {
        website: "https://www.rustyrobot.io",
        tokenPage: "https://docs.rustyrobot.io/rust-token/rust-token-info",
      },
      SocialLinks: {
        xTwitter: "https://x.com/rustyrobotcc",
        discord: "https://discord.com/invite/8unzwNPYYC",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x932059511253f55b9c288f9a9c4e54561f0fd05c",
        },
      ],
    },
  },
  {
    Address: "0x58427e8577f704d71d02ab7716e2aa7c02c4c1a2",
    ImageURL: "/assetLogos/bitc.svg",
    Name: "Biotacoin",
    Symbol: "BiTC",
    Details: {
      Description:
        "<b>BiTC</b> or <b>Biotacoin</b> is a <i>Memecoin</i> Token. It is a parody token of the well-known cryptocurrency <i>Bitcoin</i>. The project plays with the words <i>Bitcoin</i>, its creator <i>Satoshi Nakamoto</i>, and the asset <i>IOTA</i>, implying that this token is the creation of <b>Satoshi Nakamiota</b>.<br><br><b>BiTC</b>, at the time of writing, has no utility; But the project promises future development in three areas called <i>Growth</i>, <i>Meme Bonds</i>, and <i>NFT Staking</i>. Each of these areas has defined <i>allocations</i> according to the <i>Tokenomics</i>, so it's likely that the tokens not yet distributed will be allocated to users once the rules for each of these three areas are established. <br>The project also has its own <i>NFT Collection</i> called <b>Biotans</b>.<br><br>The <i>creators/developers</i> of the <b>BiTC Token</b> are not publicly known (as of this writing).",
      OfficialLinks: {
        website: "https://biotacoin.xyz/",
        tokenPage: "https://docs.biotacoin.xyz/about/usdbitc-coin",
      },
      SocialLinks: {
        xTwitter: "https://x.com/biotacoin",
        telegram: "https://t.me/biotacoin",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xc1611a0f8ad290d770c33cdd2bb509e40471f22d",
        },
      ],
    },
  },
  {
    Address: "0x2ffe5c149d1bb91e34ed137b63fe4ce00065aad3",
    ImageURL: "/assetLogos/ink.png",
    Name: "INK Token",
    Symbol: "INK",
    Details: {
      Description:
        "<b>INK</b> is one of the two mains Token of the <i>Grumpy Squid Project</i>.<br><br>The <i>Grumpy Squid Project</i> is a project where the developers place particular emphasis on the economic system their two mains tokens, <b>INK</b> and <b>SQUID</b> (<i>SQUID</i> was launched on <i>ShimmerEVM</i> and features similar characteristics to <i>INK</i>).<br>The project introduces an innovative economic system where a 10% fee is charged for each <b>INK</b> Swap Transaction on a decentralized exchange (DEX). Out of this fee, 3% is burned, and the remaining 7% is redistributed to all <b>INK</b> holders relative to their holdings. This transforms this asset into a <b>deflationary Token</b> while simultaneously rewarding its holders.<br><br><i>IotaCap</i> has firsthand verified this mechanism, confirming that the postulate holds true in this transaction:<br><i><b><a target=`_blank` href='https://explorer.evm.shimmer.network/tx/0xa7904bbe43bd83c47f9d22e2a521ab73805440b716f6c0a0fcf49795ee7c6a0c'>- Link to EVM Swap Transaction Contract</a></b></i><br>And this occurs as a result of a modification of the ERC20 standart <i>_transfer()</i> function in the <i>Squid Token Contract</i>. Please refer to line 801 of the contract:<br><i><b><a target=`_blank` href='https://explorer.evm.shimmer.network/token/0x32D5b7f003b679E387bD986a89232cccC6996b54?tab=contact_code'>- Link to Code Contract</a></b></i><br><br><i>Grumpy Squid Project</i> promises on its website other future internal projects such as a series of Games and NFT collections. At the moment there is a game available in his website called <i>Tentacle Trials</i>.<br><br>The Creators/Developers of <i>Grumpy Squid Project</i> are not public knowledge (as of this writing).",
      OfficialLinks: {
        website: "https://www.grumpysquid.com",
        tokenPage: "https://www.grumpysquid.com/whitepaper",
      },
      SocialLinks: {
        xTwitter: "https://x.com/grumpy__squid",
        discord: "https://discord.com/invite/F3wdPbRHaF",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x8d7b2ef3db26091f507f799652b7b820dd734d4c",
        },
      ],
    },
  },
  {
    Address: "0x83ec9db168c03bb827dc48839f21430d819be649",
    ImageURL: "/assetLogos/vrom.png",
    Name: "VROM",
    Symbol: "VROM",
    Details: {
      Description:
        "<b>VROM</b> is a <i>Memecoin</i> Token. It's a clear parody of the popular and well-known IOTA Community User of the same name. The user <b><i>Vrom</i></b> (<i>@Vrom14286662 in X</i>) is a renowned user, positive sharer, and promoter of news within the IOTA Community.<br><br>No direct or indirect reference suggests that <i>Vrom</i> (the user) is the creator or active participant of the project, it's understood that <b>it's simply a kind of recognition for the user</b>.<br><br>The Token does not have its own website, X Account, nor any contact method.<br>The creators/developers of <i>VROM Token</i> are not publicly known (as of this writing).",
      OfficialLinks: {},
      SocialLinks: {},
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xba184c635bf64d1bbf6a4539ee905508cb02d6ed",
        },
      ],
    },
  },
  {
    Address: "0x34a85ddc4e30818e44e6f4a8ee39d8cba9a60fb3",
    ImageURL: "/assetLogos/lum.svg",
    Name: "MagicSea Lum",
    Symbol: "LUM",
    Details: {
      Description: `<b>LUM</b> is the Utility Token of the <b>MagicSea Platform</b>.<br><br><b>MagicSea</b> (formerly known as <i>MagicSea</i>) is a Multichain platform that focuses primarily on its DEX product (<i>UniswapV2</i> fork), being, as of this writing, the DEX with the most liquidity on the <i>IOTA EVM Network</i> and the <i>ShimmerEVM Network</i>(according to the amount of token liquidity in their main contracts).<br><i>MagicSea</i> also offers other products such as the ability to <i>Stake</i> and <i>Farm</i> certain selected tokens, an <i>NFT Marketplace</i>, a <i>Token Fairlaunch Platform</i>, among other products.<br><br>The <b>LUM Token</b> is delivered as a <b>token reward</b> in <i>MagicSea</i> platform products such as <i>entering Liquidity into the pools</i>, and in <i>Earn products</i> such as <i>Farm</i> and <i>Stake</i>, so the main use of the <i>LUM Token</i> is to be an <b>additional economic incentive for users</b>, attracting this way more liquidity to the platform.<br><br><b>LUM Token</b> was originally launched on the <i>ShimmerEVM Network</i>, and the platform offers its own <i>Bridge</i> (via <i>LayerZero</i>) so that users can have this token on the <i>IOTA EVM Network</i>.<br>The Token does not have a defined <i>MaxSupply</i>, but the <i>minting rate</i> (for user rewards) is continually reduced since the launch of the project to reduce inflation impact.`,
      OfficialLinks: {
        website: "https://app.magicsea.finance/",
        tokenPage: "https://docs.magicsea.finance/tokenomics/lum-token",
      },
      SocialLinks: {
        xTwitter: "https://x.com/MagicSeaDEX",
        discord: "https://discord.gg/zKgPXY6K97",
        telegram: "https://t.me/tangleseaglobal",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xa687eddac2648337492f37a182ca555e7e62b72a",
        },
      ],
    },
  },
  {
    Address: "0x9ccb68dc058158f4f2b09a8145bcd2c7c52bcbd7",
    ImageURL: "/assetLogos/bi.png",
    Name: "Bivreost",
    Symbol: "BI",
    Details: {
      Description:
        "<b>BI</b> is the Utility Token of the <b>Bivreost Project</b>.<br><br><b>Bivreost</b> is a <i>multichain project</i>. It is an <b>Embedded Wallet in Telegram</b> that allows users to make token transactions with other <i>Telegram</i> users (who also have a <i>Bivreost Telegram Wallet</i>) through several networks. This way, users can easily and quickly obtain a wallet and <i>send and receive tokens</i> without the known complications and delays of creating traditional wallets.<br><br><b>BI Token holders</b> have benefits in the project. In their official documentation, we can find that holders have: <i>discounts at PASS subscriptions</i>, <i>shopping discounts at Bivreost BAPPs</i>, <i>market voting and influencing decisions</i>, and <i>rewards for active participation</i>.",
      OfficialLinks: {
        website: "https://bivreost.com/",
        tokenPage: "https://docs.bivreost.com/bi-token/",
      },
      SocialLinks: {
        xTwitter: "https://x.com/bivreost",
        discord: "https://discord.com/invite/nPCYmYEhfT",
        telegram: "https://t.me/bivreost",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x5c5c516e21719c96a5fe89cf9cecfff1bf7ba3a7",
        },
      ],
    },
  },
  {
    Address: "0x159366809b6062960c97bc4edd548bafcd7812d8",
    ImageURL: "/assetLogos/audit.svg",
    Name: "Audit Token",
    Symbol: "AUDIT",
    Details: {
      Description:
        "<b>Audit Token</b> or <b>Audit</b> is the Main Utility Token of the company <i>AuditONE</i>, an auditing company that specializes in audits of Web3 <i>Smart Contracts</i> and <i>AI-systems</i>.<br><br>According to the <i>Tokenomics section</i> of the <i>Litepaper</i> that we can find on its Official Website, the Stakeholders (it is not clear if it refers to the Audit Token itself, to a third party or to the LP token of a DEX Pool) will be rewarded with <i>Audit Token</i>, and the same for the Audit Token Stakeholders who will be rewarded with benefits in <i>AuditONE products</i> and services (citing them: 'Saas AI tooling, coverage pools, alpha research and the already know security services').<br><br><i>Audit Token</i> has been developed by the company <i>AuditONE</i> (@auditone_dao on <i>X</i>) based in <i>Cologne, Germany</i> and its <i>CEO/Director</i> is <i>Adrien Resch</i> (@entropyishigh on <i>X</i>).",
      OfficialLinks: {
        website: "https://www.auditone.io",
        tokenPage: "https://www.auditone.io/tokenomics",
      },
      SocialLinks: {
        xTwitter: "https://x.com/auditone_dao",
        discord: "https://discord.com/invite/n9jXmCw5d5",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xba7ac526ee9d29209baedccb67c6d3a228644982",
        },
      ],
    },
  },

  {
    Address: "0x7c311ad614ca8b7441739ceab8a218587ecc6554",
    ImageURL: "/assetLogos/giga.svg",
    Name: "GigaFish",
    Symbol: "GIGA",
    Details: {
      Description:
        "<b>GIGA</b> is a <i>Memecoin</i> Token. His representation is a <i>fish</i> characterized by an expression and appearance that evoke the figure of a typical <i>'Chad'</i> or <i>'GigaChad'</i>. (a term popularized in the <i>crypto ecosystem</i> that represents an extremely successful or dominant profile in the volatile crypto market).<br><br>The creators/developers of <i>GIGA Token</i> are not publicly known (as of this writing).",
      OfficialLinks: {
        website: "https://www.gigafish.wtf/",
        tokenPage: "https://www.gigafish.wtf/#Tokenomics",
      },
      SocialLinks: {
        xTwitter: "https://x.com/GigachadFish",
        telegram: "https://t.me/+6JFDutS878UyMTQ0",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x631a3590e570b95f9ece54025976fd8e537277c2",
        },
      ],
    },
  },
  {
    Address: "0x513e7e050ea8a7606c42b5a4fab1b4f63c29b898",
    ImageURL: "/assetLogos/chicks.png",
    Name: "Chicks",
    Symbol: "CHICKS",
    Details: {
      Description:
        "<b>CHICKS</b> is a <i>Memecoin</i> Token, it’s emblem figure is a <b>chicken with kicks</b> (shoes). His webpage shows various representations of this figure.<br><br>The creators/developers of <i>CHICKS Token</i> are not listed on their website and are not publicly known (as of this writing).",
      OfficialLinks: {
        website: "https://chickswifkicks.com/",
      },
      SocialLinks: {
        xTwitter: "https://x.com/ChicksWifKicks",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x70edc167536f0c26e93b3dc879a4d0662c0bda7f",
        },
      ],
    },
  },
  {
    Address: "0xfabfbc185425dc87e5871d4e8705c5536991fd9a",
    ImageURL: "/assetLogos/hold.png",
    Name: "HODLHamster",
    Symbol: "HODL",
    Details: {
      Description:
        "<b>HODL</b> (<i>HODLHamster</i>) is a <i>memecoin</i> Token, it's emblem figure is a <b>cartoon Hamster</b>.<br><br>The project self-identifies as the <i>'1st market-related storytelling meme token on Shimmer EVM'</i>. This approach establishes a <b>storyline centered around the HODLHamster, the main character of this project</b>. As market events progress, the decisions made by the Hamster in it's narrative will be influenced by the challenges and situations encountered in the market.<br><br>According to its official website (on the 'privacy-policy' page), the project leader is <i>Tobias Müller-Edelmann</i>, and the project is based in <i>Fellbach, Germany</i>. As of the time of writing, <i>IotaCap</i> has been unable to find links to the project leader's social networks. Previously, at <i>ShimmerCap</i>, we linked the project to the <i>Discord User `LECTOR 02 (@lector02)`</i>, but this is not official information as it was obtained indirectly through the project's official Discord channel.<br><br><b>HODLHamster Token</b> was originally launched on the <i>ShimmerEVM Network</i>. At the time of writing, the Token can be bridged from <i>ShimmerEVM</i> to <i>IOTA EVM</i> via the <i>LayerZero Bridge</i> implemented on the <i>Rusty Robot Country Club</i> website (<u>www.rustyrobot.io/bridge</u>).<br><br>Its Max TotalSupply is 100,000M Tokens, that amount was minted at the time of contract deploy and no more tokens can be minted, so it will never be greater than that.",
      OfficialLinks: {
        website: "https://hodlhamster.info/",
        tokenPage:
          "https://hodlhamster.info/wp-content/uploads/2024/05/GG4z0ZkXkAAMlnx-scaled.jpeg",
      },
      SocialLinks: {
        xTwitter: "https://x.com/HODL_Hamster",
        discord: "https://discord.com/invite/WeBSqKVGDM",
        telegram: "https://t.me/HODL_Hamster",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0x720637236c9ec930a95b72ea0b8f566c1dba307e",
        },
      ],
    },
  },
  {
    Address: "0x4eb8e03461360c2acbb4b5963a18b8f9faee8221",
    ImageURL: "/assetLogos/deepr.svg",
    Name: "Deepr Token",
    Symbol: "DEEPR",
    Details: {
      Description: `<b>DEEPR</b> is the Utility Token of the <i>Deepr Finance</i> Platform.<br><br><i>Deepr Finance</i> is a <i>DeFi Borrow/Lending</i> platform, being a <i>Compound</i> fork.<br>As of writing this, <i>Deepr Finance</i> is an active platform on <i>ShimmerEVM network</i> and with the prospect of launching in the second part of 2024 on <i>IOTA EVM network</i>. <br><br><b>DEEPR Token</b> is delivered as a reward for users who make Supply Collateral and/or Borrow in certain Markets of the <i>Deepr Finance</i> platform, so the main use of the <b>DEEPR</b> Token is to be an additional economic incentive for users, attracting this way more liquidity to the platform.<br><br><b>Deepr Token</b> (and <i>Deepr Finance</i>) has been developed by the company <i>Nakama Labs</i> (@Nakama_Labs on XTwitter)`,
      OfficialLinks: {
        website: "https://www.deepr.finance/",
        tokenPage: "https://docs.deepr.finance/tokenomics/",
      },
      SocialLinks: {
        xTwitter: "https://twitter.com/DeeprFinance",
        discord: "https://discord.gg/kfyNmmbvhJ",
      },
      Markets: [
        {
          exchange: "MagicSea",
          assets: ["IOTA"],
          type: "DEX Pool",
          poolType: "ms_v2",
          address: "0xd0a66e093e057ba806a3dc49f6066153c9398196",
        },
      ],
    },
    isNew: true,
  },
];
