import {
  Box,
  Heading,
  Stack,
  TableContainer,
  useMediaQuery,
} from "@chakra-ui/react";
import { GetGeneratedHeroTable } from "./partials/HeroTable";
import { backgroundGeneralColor, thirdColor } from "../customTheme";
import { fastMode } from "../constants";
import GetSortedItemsData, { GetSortedFastItemsData } from "./core/ItemData";
import { GetGeneratedHeroCards } from "./partials/HeroCards";

export default function Hero() {
  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const itemsData = fastMode ? GetSortedFastItemsData() : GetSortedItemsData();

  return (
    <Box background={backgroundGeneralColor}>
      <Heading
        color={thirdColor}
        textAlign={isMobile ? "right" : "left"}
        py={{ base: 2.5, md: 2.5 }}
        px={isMobile ? "5" : "20"}
        fontSize={isMobile ? "17" : "2xl"}
        transition="transform 250ms"
        _hover={{
          transform: "scale(1.002)",
        }}
      >
        Cryptocurrency Prices by Market Cap
      </Heading>
      {GetGeneratedHeroCards(itemsData)}
      <TableContainer background={backgroundGeneralColor}>
        <Stack as={Box} textAlign={"center"} py={{ base: 3, md: 3 }}>
          {GetGeneratedHeroTable(itemsData)}
        </Stack>
      </TableContainer>
    </Box>
  );
}
