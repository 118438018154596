import { shimmercapApiUrl } from "../constants";
import axios from "axios";
import {
  ArticleItem,
  CommunityPostItem,
  errorArticleItem,
  errorCommunityPostItem,
} from "../types";

// Obtain Community Posts Array (Fast Data)
export async function getHeroCardsDataFastDataRaw() {
  const requestUrl = `${shimmercapApiUrl}community_posts_fast_data`;
  try {
    const response = await axios.get(requestUrl);
    const communityPostsArray = response.data.communityPosts;
    const articlesArray = response.data.articles;

    // CommunityPostsArray
    let postItems: CommunityPostItem[] = [];
    for (let i = 0; i < communityPostsArray.length; i++) {
      const item: CommunityPostItem = {
        CoinmarketcapPostId: communityPostsArray[i].coinmarketcapPostId,
        UserAndDate: communityPostsArray[i].userAndDate,
        UserAvatarUrl: communityPostsArray[i].userAvatarUrl,
        PostText: communityPostsArray[i].postText,
        PostImageUrl:
          communityPostsArray[i].postImageUrl === ""
            ? undefined
            : communityPostsArray[i].postImageUrl,
        isExternalArticle: communityPostsArray[i].isExternalArticle,
      };

      postItems.push(item);
    }

    // CommunityPostsArray
    let articlesItems: ArticleItem[] = [];
    for (let i = 0; i < articlesArray.length; i++) {
      const item: ArticleItem = {
        Title: articlesArray[i].title,
        Content: articlesArray[i].content,
        Username: articlesArray[i].username,
        Date: articlesArray[i].date,
        BannerUrl: articlesArray[i].bannerUrl,
        AvatarUrl: articlesArray[i].avatarUrl,
        Link: articlesArray[i].link,
      };

      articlesItems.push(item);
    }

    return { postItems, articlesItems };
  } catch (error) {
    console.log(error);
    const postItems: CommunityPostItem[] = Array(6).fill(
      errorCommunityPostItem
    );
    const articlesItems: ArticleItem[] = Array(6).fill(errorArticleItem);
    return { postItems, articlesItems };
  }
}
