import {
  Text,
  Image,
  HStack,
  Table,
  Tbody,
  Tr,
  Td,
  Flex,
  Button,
  Center,
} from "@chakra-ui/react";

import { CommunityPostItem, HeroItemStats } from "../../../types";
import { backgroundGeneralColor, thirdColor } from "../../../customTheme";

let bg_hover_row_lightGray = "gray.100"; // Hover color Item
let bg_rb_gray = "gray.200"; // Hover color Names Row

export function GetCommunityPostsRows(
  itemsData: CommunityPostItem[],
  isMobile: boolean
) {
  // Generating Rows
  let postsRows: JSX.Element[] = [];
  for (let i = 0; i < itemsData.length; i++) {
    postsRows.push(CommunityPostRow(itemsData[i], isMobile));
  }
  postsRows.push(AddShowMoreButton(isMobile));

  return (
    <Table
      variant="simple"
      background={backgroundGeneralColor}
      colorScheme="blackAlpha"
      textColor={thirdColor}
      sx={{
        "th, td": {
          borderWidth: "1.7px",
          borderColor: "black",
        },
        th: {
          fontWeight: "bold",
        },
      }}
    >
      <Tbody>{postsRows}</Tbody>
    </Table>
  );
}

function CommunityPostRow(itemsData: CommunityPostItem, isMobile: boolean) {
  const isLongText = itemsData.PostText.length > 140;

  return (
    <Tr
      cursor="pointer"
      _hover={{ bg: bg_hover_row_lightGray }}
      onClick={() => {
        if (String(itemsData.UserAvatarUrl) !== "loader.gif") {
          window.open(
            `https://coinmarketcap.com/community/post/${itemsData.CoinmarketcapPostId}`,
            "_blank"
          );
        }
      }}
    >
      <Td
        paddingLeft={isMobile ? 1 : 2}
        paddingTop={isMobile ? "5px" : "15px"}
        paddingBottom={isMobile ? "5px" : "15px"}
        paddingRight={isMobile ? "3px" : 0}
      >
        <HStack>
          <Image
            src={String(itemsData.UserAvatarUrl)}
            borderRadius="full"
            boxSize="14px"
            objectFit="cover"
          />
          <Text
            lineHeight={isMobile ? "normal" : "inherit"}
            fontWeight={"bold"}
            fontSize={isMobile ? "8" : "11"}
          >
            {itemsData.UserAndDate}
          </Text>
        </HStack>

        <Text
          paddingLeft={isMobile ? 1 : 2}
          fontSize={isMobile ? "9" : "11"}
          paddingTop={isMobile ? "4px" : "0"}
          lineHeight={isMobile ? "normal" : "inherit"}
          maxWidth="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="normal"
        >
          {itemsData.PostText}
          {isLongText ? ".....  " : ""}
          {isLongText ? <b>{"See more..."}</b> : ""}
        </Text>

        {itemsData.PostImageUrl && (
          <Center>
            <Flex paddingTop={1} marginLeft={isMobile ? 1 : 2}>
              <Image
                src={String(itemsData.PostImageUrl)}
                height={isMobile ? "60px" : "90px"}
                objectFit="cover"
              />
            </Flex>
          </Center>
        )}

        {itemsData.isExternalArticle && (
          <Text
            marginTop={1}
            fontWeight={"bold"}
            fontSize={"10"}
            textAlign={"center"}
          >
            {"[Article Embedded in Post]"}
          </Text>
        )}
      </Td>
    </Tr>
  );
}

function AddShowMoreButton(isMobile: boolean) {
  return (
    <Tr
      cursor="pointer"
      _hover={{
        bg: bg_hover_row_lightGray,
      }}
      onClick={() => {
        window.open(
          `https://coinmarketcap.com/community/coins/iota/top/`,
          "_blank"
        );
      }}
    >
      <Td textAlign="center" p={isMobile ? 0 : 1}>
        <Button
          size={isMobile ? "sm" : "md"}
          fontSize={isMobile ? "9" : "13"}
          fontWeight={"bold"}
        >
          Show More
        </Button>
      </Td>
    </Tr>
  );
}
