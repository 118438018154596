import { extendTheme } from "@chakra-ui/react";
import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools";

export const primaryColor = "#711AA1"; // (Grape)
export const secondaryColor = "#5A7D7C"; // (Hookers green)
export const thirdColor = "gray.800"; // (Gray almost Black)
export const fourColor = "#000000"; // (Black)

export const hoverPrimaryColor = "gray.300";
export const hoverSecondaryColor = "gray.200";

export const backgroundGeneralColor = "#F5F3F5"; // (White Smoke)
export const backgroundNavFooterColor = "#E1E1E1"; // (White Smoke)
export const backgroundNavBarMobile = "gray.600";

export const customTheme = extendTheme({
  styles: {
    global: (props: Record<string, any> | StyleFunctionProps) => ({
      "html, body": {
        background: mode(
          backgroundNavFooterColor,
          backgroundNavFooterColor
        )(props), //mode(light mode color, dark mode color)
      },
    }),
  },
});
