import { Box, useTheme } from "@chakra-ui/react";
import { FC } from "react";

interface SentimentMeterProps {
  value: number; // El valor del sentimiento entre 0 y 100
}

const SentimentMeter: FC<SentimentMeterProps> = ({ value }) => {
  const theme = useTheme();

  // Limita el valor entre 0 y 100
  const limitedValue = Math.max(0, Math.min(100, value));

  return (
    <Box width="80%" maxWidth="500px" mx="auto" mt="4">
      <Box
        height="24px"
        bgGradient="linear(to-r, red.500, orange.400, yellow.400, green.300, green.500)"
        borderRadius="md" // Ajusta el borde redondeado a solo las esquinas
        border="4px solid black" // Agrega el borde negro grueso
        position="relative"
      >
        <Box
          position="absolute"
          top="0" // Posiciona el triángulo en la parte superior de la barra
          left={`${limitedValue}%`}
          transform="translateX(-50%)"
          width="0"
          height="0"
          borderLeft="6px solid transparent"
          borderRight="6px solid transparent"
          borderTop="12px solid purple" // Triángulo invertido
          borderTopColor="purple"
          borderBottom="8px solid black" // Borde negro menos grueso
        />
      </Box>
    </Box>
  );
};

export default SentimentMeter;
