import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { customTheme } from "./customTheme";
import NavBar from "./pages/partials/NavBar";
import Footer from "./pages/partials/Footer";
import Hero from "./pages/Hero";
import AssetPage from "./pages/AssetPage";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import ApiServicePage from "./pages/ApiServicePage";
import MyPortfolioPage from "./pages/MyPortfolioPage";
import ContactUsPage from "./pages/ContactUsPage";
import ErrorPage from "./pages/ErrorPage";
import DisclaimerPage from "./pages/DisclaimerPage";

ReactGA.initialize("G-KL1ZY0DP8M");

const AnalyticsWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view when route changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  return null; // or any other component you may want to render
};
export const App = () => (
  <ChakraProvider theme={customTheme}>
    <BrowserRouter>
      <AnalyticsWrapper />
      <NavBar />
      <Routes>
        <Route path="/" element={<Hero />}></Route>
        <Route path="/my-portfolio" element={<MyPortfolioPage />}></Route>
        <Route path="/api" element={<ApiServicePage />}></Route>
        <Route path="/contact" element={<ContactUsPage />}></Route>
        <Route path="/disclaimer" element={<DisclaimerPage />}></Route>
        <Route path="/asset/:assetSymbol" element={<AssetPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </ChakraProvider>
);
