import { useEffect, useState } from "react";
import getDbDataFetcher, {
  getDbFastArrayDataFetcher,
  getDbFastDataFetcher,
} from "../../core/RpcDataFetcher";
import {
  ArticleItem,
  CommunityPostItem,
  HeroItemStats,
  WhitelistTokenData,
  errorItem,
  loadingArticleItem,
  loadingCommunityPostItem,
  loadingItem,
} from "../../types";
import { fastMode, whitelistTokenData } from "../../constants";
import { getHeroCardsDataFastDataRaw } from "../../core/DynamodbDataFetcher";

export default function GetSortedItemsData() {
  let itemsData = [];
  for (let i = 0; i < whitelistTokenData.length; i++) {
    itemsData.push(GetItemData(whitelistTokenData[i]));
  }

  // Sort By MarketCap
  itemsData.sort((a, b) => b.MarketCapNumber - a.MarketCapNumber);

  return itemsData;
}

export function GetSortedFastItemsData() {
  let itemsData: HeroItemStats[] = GetSortedFastArrayData();

  // Sort By MarketCap
  itemsData.sort((a, b) => b.MarketCapNumber - a.MarketCapNumber);

  return itemsData;
}

export function GetItemData(whitelistToken: WhitelistTokenData) {
  const [itemDataStruct, setItemDataStruct] = useState(loadingItem);

  useEffect(() => {
    const fetchData = async () => {
      setItemDataStruct(
        fastMode
          ? await getDbFastDataFetcher(whitelistToken)
          : await getDbDataFetcher(whitelistToken)
      );
    };

    fetchData();
  }, [whitelistToken]);

  return itemDataStruct;
}

export function GetSortedFastArrayData() {
  const [arrayDataStruct, setArrayDataStruct] = useState<HeroItemStats[]>(
    Array(whitelistTokenData.length).fill(loadingItem)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDbFastArrayDataFetcher();
        if (data[0] !== errorItem) setArrayDataStruct(data);
      } catch (error) {}
    };

    // Fetch data immediately on mount
    fetchData();

    // Set up interval to fetch data every 1 minute
    const intervalId = setInterval(fetchData, 60000); // 60000 ms = 1 minute

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return arrayDataStruct;
}

export function GetHeroCardsDataFastDataArrays() {
  const arr1: CommunityPostItem[] = Array(6).fill(loadingCommunityPostItem);
  const [arrayCPDataStruct, setArrayCPDataStruct] =
    useState<CommunityPostItem[]>(arr1);
  const arr2: ArticleItem[] = Array(6).fill(loadingArticleItem);
  const [arrayADataStruct, setArrayADataStruct] = useState<ArticleItem[]>(arr2);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { postItems, articlesItems } =
          await getHeroCardsDataFastDataRaw();
        setArrayCPDataStruct(postItems);
        setArrayADataStruct(articlesItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return { arrayCPDataStruct, arrayADataStruct };
}

// NOT USED
/*
export function GetPriceRangeData(
  assetAddress: string,
  daysRange: number,
  interval: number
) {
  const [priceRangeDataStruct, setPriceRangeDataStruct] =
    useState(loadingChart);

  useEffect(() => {
    const fetchData = async () => {
      setPriceRangeDataStruct(
        await getPriceRangeDbDataFetcher(assetAddress, daysRange, interval)
      );
    };

    fetchData();
  }, []);

  return priceRangeDataStruct;
}
*/
