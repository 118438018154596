import {
  Card,
  CardBody,
  Text,
  Heading,
  SimpleGrid,
  Box,
  Image,
  useMediaQuery,
  HStack,
  Center,
} from "@chakra-ui/react";
import { HeroItemStats } from "../../types";
import { backgroundGeneralColor } from "../../customTheme";
import { GetTrendingRows } from "./HeroCardsPartials/TrendingAssetsCard";
import { GetCommunityPostsRows } from "./HeroCardsPartials/CommunityPostsCard";
import { GetUserfulArticlesCard } from "./HeroCardsPartials/UserfulArticlesCard";
import { GetHeroCardsDataFastDataArrays } from "../core/ItemData";
import { GetSentimentContent } from "./HeroCardsPartials/SentimentCard";

const cardColor = "#e0d9e4";

export function GetGeneratedHeroCards(itemsData: HeroItemStats[]) {
  const { arrayCPDataStruct, arrayADataStruct } =
    GetHeroCardsDataFastDataArrays();

  const [isMobile] = useMediaQuery("(max-width: 800px)");
  const cardsTextInitMargin = isMobile ? 4 : 6;

  const cardsHeight = isMobile ? "220px" : "330px";
  const cardPostsMaxHeight = isMobile ? "150px" : "240px";

  const cardsSimpleGridTemplate = isMobile
    ? "repeat(2, 1fr)"
    : "repeat(4, 1fr)";

  return (
    <SimpleGrid spacing={4} templateColumns={cardsSimpleGridTemplate}>
      <Card bgColor={cardColor} height={cardsHeight} overflow="hidden">
        <CardBody
          padding={isMobile ? "12px" : "17px"}
          paddingTop={isMobile ? "17px" : "20px"}
        >
          <HStack justifyContent="center" alignItems="center">
            <Heading textAlign={"center"} size={isMobile ? "sm" : "md"}>
              HOT Trending
            </Heading>
            <Heading textAlign={"center"} size={isMobile ? "sm" : "md"}>
              🔥
            </Heading>
          </HStack>
          <Box marginTop={isMobile ? 7 : 3}>{GetTrendingRows(itemsData)}</Box>
        </CardBody>
      </Card>
      <Card bgColor={cardColor} height={cardsHeight} overflow="hidden">
        <CardBody
          padding={isMobile ? "12px" : "20px"}
          paddingTop={isMobile ? "17px" : "20px"}
        >
          <HStack justifyContent="center" alignItems="center">
            <Heading size={isMobile ? "sm" : "md"}>IOTA Community</Heading>
            <Image
              src={"/assetsPageImages/iotaLogo.svg"}
              maxHeight="18px"
              objectFit="cover"
            />
          </HStack>
          <Card
            marginTop={cardsTextInitMargin - 2}
            bgColor={backgroundGeneralColor}
            maxHeight={cardPostsMaxHeight}
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
          >
            {GetCommunityPostsRows(arrayCPDataStruct, isMobile)}
          </Card>

          <HStack
            position="absolute"
            bottom="0"
            right={isMobile ? "10px" : "17px"}
            paddingBottom={isMobile ? "2px" : "5px"}
            spacing={isMobile ? 1 : 2}
            whiteSpace="nowrap" // Asegura que el texto no se rompa en varias líneas
          >
            <Text fontSize={isMobile ? "9.5px" : "xs"} whiteSpace="nowrap">
              Powered by
            </Text>
            <Image
              src={"/assetsPageImages/cmc.svg"}
              height={isMobile ? "12px" : "15px"}
              objectFit="cover"
            />
          </HStack>
        </CardBody>
      </Card>
      <Card bgColor={cardColor} height={cardsHeight} overflow="hidden">
        <CardBody
          padding={isMobile ? "12px" : "20px"}
          paddingTop={isMobile ? "17px" : "20px"}
        >
          <HStack justifyContent="center" alignItems="center">
            <Heading size={isMobile ? "sm" : "md"} textAlign={"center"}>
              Useful Articles
            </Heading>
            <Heading size={isMobile ? "sm" : "md"} textAlign={"center"}>
              📜
            </Heading>
          </HStack>
          {GetUserfulArticlesCard(arrayADataStruct, isMobile)}
        </CardBody>
      </Card>
      <Card bgColor={cardColor} height={cardsHeight} overflow="hidden">
        <CardBody
          padding={isMobile ? "12px" : "20px"}
          paddingTop={isMobile ? "17px" : "20px"}
        >
          <HStack justifyContent="center" alignItems="center">
            <Heading size={isMobile ? "sm" : "md"} textAlign={"center"}>
              IOTA Market Sentiment 🌡️
            </Heading>
          </HStack>
          {GetSentimentContent(itemsData, isMobile)}
        </CardBody>
      </Card>
    </SimpleGrid>
  );
}
