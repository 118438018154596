import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Heading,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [isMobile] = useMediaQuery("(max-width: 870px)");

  const toast = useToast();

  const handleSubmit = (event: { preventDefault: () => void; target: any }) => {
    event.preventDefault();

    const form = event.target;

    emailjs
      .sendForm(
        "service_iotacap",
        "template_iotacap_contact",
        form,
        "LJWvkEMgd2nabu1g5"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast({
            title: "Email sent.",
            description: "Your message has been sent successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          form.reset(); // clean form after send
        },
        (error) => {
          console.log(error.text);
          toast({
            title: "Error.",
            description: "There was a problem sending the email.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Box
      p={5}
      width={isMobile ? "300px" : "800px"}
      mx="auto"
      bg={"#eaeaea"}
      rounded={"3xl"}
    >
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              placeholder="Your name"
              borderColor="black"
              _hover={{ borderColor: "black" }}
            />
          </FormControl>

          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              type="email"
              placeholder="Your email"
              borderColor="black"
              _hover={{ borderColor: "black" }}
            />
          </FormControl>

          <FormControl id="message" isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea
              name="message"
              placeholder="Your message"
              borderColor="black"
              _hover={{ borderColor: "black" }}
            />
          </FormControl>

          <Button colorScheme="#711AA1" bgColor={"#711AA1"} type="submit">
            Send
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ContactForm;
