import { Box, Heading, Container, Text, Stack, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { backgroundGeneralColor } from "../customTheme";

export default function ErrorPage() {
  return (
    <Box
      bg={backgroundGeneralColor}
      minHeight="85vh"
      display="flex"
      flexDirection="column"
    >
      <Container maxW="container.xl" flex="1">
        <Stack
          as={Box}
          textAlign={"center"}
          justify="center"
          align="center"
          height="100%"
        >
          <Text
            fontSize={{ base: "80px", sm: "100px", md: "130px" }}
            color={"gray.800"}
            align={"left"}
            marginTop={{ base: "80px", sm: "100px", md: "130px" }}
            maxW="1000px"
            alignSelf={"center"}
          >
            ☔
          </Text>
          <Heading
            color={"gray.800"}
            fontWeight={"extrabold"}
            fontSize={{ base: "40px", sm: "50px", md: "70px" }}
            marginTop={{ base: "10px", sm: "10px", md: "20px" }}
            lineHeight={"110%"}
          >
            404
          </Heading>
          <Heading
            color={"gray.800"}
            fontWeight={"normal"}
            fontSize={{ base: "2xl", sm: "2xl", md: "5xl" }}
            lineHeight={"110%"}
          >
            Page Not Found
          </Heading>
          <Button
            as={Link}
            to="/"
            colorScheme="#711AA1"
            bgColor={"#711AA1"}
            variant="solid"
            marginTop="20px"
          >
            Go Home
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}
