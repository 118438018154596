import {
  Box,
  Text,
  HStack,
  Button,
  GridItem,
  Image,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  extendTheme,
} from "@chakra-ui/react";
import {
  ButtonKeys,
  MarketKeys,
  MarketsInterface,
  OfficialLinksInterface,
  SocialLinksInterface,
} from "../../types";
import {
  FaDiscord,
  FaGithub,
  FaReddit,
  FaSquareXTwitter,
  FaTelegram,
} from "react-icons/fa6";
import { TbExternalLink, TbWorld } from "react-icons/tb";
import { MdOutlineRequestPage } from "react-icons/md";
import { GiToken } from "react-icons/gi";
import { thirdColor } from "../../customTheme";

export function addSocialBlock(socialLinks: SocialLinksInterface) {
  return (
    <Box>
      <HStack fontSize={"md"} marginBlockStart={"7"}>
        <Box textColor={thirdColor} fontWeight="normal">
          Socials:
        </Box>
      </HStack>
      <HStack alignItems="center" spacing="8px" marginBlockStart={1}>
        {Object.keys(socialLinks).length > 0 ? (
          Object.entries(socialLinks).map(([key, value], _) =>
            addSocialButton(key, value)
          )
        ) : (
          <Text textColor={thirdColor} fontWeight="light" marginInlineStart={5}>
            No Social Links
          </Text>
        )}
      </HStack>
    </Box>
  );
}

function addSocialButton(key: string, urlLink: string) {
  const socialKeys: ButtonKeys = {
    xTwitter: { textButton: "X", icon: FaSquareXTwitter },
    discord: { textButton: "Discord", icon: FaDiscord },
    telegram: { textButton: "Telegram", icon: FaTelegram },
    reddit: { textButton: "Reddit", icon: FaReddit },
  };

  return (
    <Button
      colorScheme={"blackAlpha"}
      bgColor={"gray.700"}
      size={"sm"}
      fontWeight={"bold"}
      textColor={"white"}
      leftIcon={socialKeys[key].icon({ color: "white" })}
      cursor={"pointer"}
      variant="solid"
      onClick={() => {
        window.open(urlLink, "_blank");
      }}
    >
      {socialKeys[key].textButton}
    </Button>
  );
}

export function addOfficialLinksBlock(officialLinks: OfficialLinksInterface) {
  return (
    <Box>
      <HStack fontSize={"md"} marginBlockStart={"7"}>
        <Box textColor={thirdColor} fontWeight="normal">
          Official Links:
        </Box>
      </HStack>
      <HStack alignItems="center" spacing="8px" marginBlockStart={1}>
        {Object.keys(officialLinks).length > 0 ? (
          Object.entries(officialLinks).map(([key, value], _) =>
            addOfficialLinkButton(key, value)
          )
        ) : (
          <Text textColor={thirdColor} fontWeight="light" marginInlineStart={5}>
            No Official Links
          </Text>
        )}
      </HStack>
    </Box>
  );
}

function addOfficialLinkButton(key: string, urlLink: string) {
  const officialLinkKeys: ButtonKeys = {
    website: { textButton: "Website", icon: TbWorld },
    tokenPage: { textButton: "Asset Page", icon: MdOutlineRequestPage },
    github: { textButton: "GitHub", icon: FaGithub },
  };

  return (
    <Button
      colorScheme={"blackAlpha"}
      bgColor={"gray.700"}
      size={"sm"}
      fontWeight={"bold"}
      textColor={"white"}
      leftIcon={officialLinkKeys[key].icon({ color: "white" })}
      cursor={"pointer"}
      variant="solid"
      onClick={() => {
        window.open(urlLink, "_blank");
      }}
    >
      {officialLinkKeys[key].textButton}
    </Button>
  );
}

export function addMarketsBlock(
  assetName: string,
  assetSymbol: string,
  markets: MarketsInterface[]
) {
  return (
    <Box>
      <HStack spacing="14px">
        <GiToken color={thirdColor} />
        <Box fontSize={"xl"} fontWeight={"bold"} textColor={thirdColor}>
          {assetName} Markets:
        </Box>
      </HStack>

      <TableContainer marginBlockStart={2}>
        <Table variant="simple" size="sm" colorScheme={thirdColor}>
          <Thead>
            <Tr>
              <Th>Exchange</Th>
              <Th>Type</Th>
              <Th>Pair</Th>
            </Tr>
          </Thead>
          <Tbody>
            {markets.length > 0 ? (
              markets.map((market, _) => addMarketItem(assetSymbol, market))
            ) : (
              <Tr>
                <Text
                  textColor={thirdColor}
                  fontWeight="light"
                  marginInlineStart={5}
                  marginBlockStart={2}
                >
                  No Markets Info.
                </Text>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function addMarketItem(assetSymbol: string, market: MarketsInterface) {
  const marketsKeys: MarketKeys = {
    ms_v2: {
      image: "/marketsLogos/magicsea.png",
      url: `https://app.magicsea.finance/liquidityv2/manual/:8822/add/${market.address}?showTop=true`,
    },
    ms_lb: {
      image: "/marketsLogos/magicsea.png",
      url: `https://app.magicsea.finance/liquidity/manual/:8822/add/v21/${market.address}/50?showTop=true`,
    },
  };
  return (
    <Tr>
      <Td>
        <HStack spacing="3px" alignItems="center">
          <Image
            src={marketsKeys[market.poolType].image}
            rounded={"full"}
            boxSize="7"
            mr="10px"
          />
          <Box fontSize={"l"} fontWeight={"semibold"}>
            {market.exchange}
          </Box>
        </HStack>
      </Td>
      <Td>
        <Box>
          <Box fontSize={"l"} fontWeight={"medium"}>
            {market.type}
          </Box>
        </Box>
      </Td>
      <Td>
        <Box>
          <HStack
            spacing="2px"
            alignItems="center"
            cursor={"pointer"}
            onClick={() => {
              window.open(marketsKeys[market.poolType].url || "", "_blank");
            }}
          >
            <Box fontSize={"l"} fontWeight={"semibold"}>
              {`${assetSymbol}/${market.assets}`}
            </Box>
            <TbExternalLink size={"24"} color={"black"} />
          </HStack>
        </Box>
      </Td>
    </Tr>
  );
}

export const theme = extendTheme({
  components: {
    Table: {
      variants: {
        simple: {
          th: {
            borderColor: "blue.700",
          },
          td: {
            borderColor: "blue.700",
          },
        },
      },
    },
  },
});
