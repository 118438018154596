import { shimmercapApiUrl } from "../constants";
import axios from "axios";
import {
  HeroItemStats,
  WhitelistTokenData,
  errorItem,
  notFoundItem,
} from "../types";
import { formatNumber, getPriceChangeString, pricePrettier } from "./utils";
import { whitelistTokenData } from "../constants";

export default async function getDbDataFetcher(
  whitelistToken: WhitelistTokenData
) {
  const assetAddress = whitelistToken.Address;
  const requestUrl = `${shimmercapApiUrl}asset_basic_stats_db?address=${assetAddress}`;
  try {
    const apiResponse = await axios.get(requestUrl);
    const price = Number(apiResponse.data[assetAddress].price.usd);
    const marketCap = Number(apiResponse.data[assetAddress].marketcap.usd);
    const circSupply = Number(
      apiResponse.data[assetAddress].circulating_supply
    );

    const oneHourChangeData = getPriceChangeString(
      apiResponse.data[assetAddress].price_change.usd_one_hour_change
    );
    const twoHoursChangeData = getPriceChangeString(
      apiResponse.data[assetAddress].price_change.usd_two_hours_change
    );
    const oneDayChangeData = getPriceChangeString(
      apiResponse.data[assetAddress].price_change.usd_one_day_change
    );
    const oneWeekChangeData = getPriceChangeString(
      apiResponse.data[assetAddress].price_change.usd_one_week_change
    );

    const newItem: HeroItemStats = {
      LogoUrl: whitelistToken.ImageURL,
      Name: whitelistToken.Name,
      Symbol: whitelistToken.Symbol,
      Price: `$ ${pricePrettier(price)}`,
      MarketCap: `$ ${formatNumber(marketCap)}`,
      CircSupply: `${formatNumber(circSupply)}`,
      PriceChange: {
        Hour: {
          ChangeString: oneHourChangeData[0],
          Color: oneHourChangeData[1],
        },
        TwoHours: {
          ChangeString: twoHoursChangeData[0],
          Color: twoHoursChangeData[1],
        },
        Day: { ChangeString: oneDayChangeData[0], Color: oneDayChangeData[1] },
        Week: {
          ChangeString: oneWeekChangeData[0],
          Color: oneWeekChangeData[1],
        },
      },
      MarketCapNumber: marketCap,
      isNew: whitelistToken.isNew ? true : false,
    };

    return newItem;
  } catch (error) {
    return errorItem;
  }
}

// Obtain A SINGLE ASSET From Fast Data
export async function getDbFastDataFetcher(whitelistToken: WhitelistTokenData) {
  const requestUrl = `${shimmercapApiUrl}hero_fast_data`;
  try {
    const response = await axios.get(requestUrl);
    const responseArray = response.data;

    // Get the target Address From Address
    const targetObject = responseArray.find(
      (item: any) => whitelistToken.Address in item
    )?.[whitelistToken.Address];

    const price = Number(targetObject.price.usd);
    const marketCap = Number(targetObject.marketcap.usd);
    const circSupply = Number(targetObject.circulating_supply);

    const oneHourChangeData = getPriceChangeString(
      targetObject.price_change.usd_one_hour_change
    );
    const twoHoursChangeData = getPriceChangeString(
      targetObject.price_change.usd_two_hours_change
    );
    const oneDayChangeData = getPriceChangeString(
      targetObject.price_change.usd_one_day_change
    );
    const oneWeekChangeData = getPriceChangeString(
      targetObject.price_change.usd_one_week_change
    );

    const newItem: HeroItemStats = {
      LogoUrl: whitelistToken.ImageURL,
      Name: whitelistToken.Name,
      Symbol: whitelistToken.Symbol,
      Price: `$ ${pricePrettier(price)}`,
      MarketCap: `$ ${formatNumber(marketCap)}`,
      CircSupply: `${formatNumber(circSupply)}`,
      PriceChange: {
        Hour: {
          ChangeString: oneHourChangeData[0],
          Color: oneHourChangeData[1],
        },
        TwoHours: {
          ChangeString: twoHoursChangeData[0],
          Color: twoHoursChangeData[1],
        },
        Day: { ChangeString: oneDayChangeData[0], Color: oneDayChangeData[1] },
        Week: {
          ChangeString: oneWeekChangeData[0],
          Color: oneWeekChangeData[1],
        },
      },
      MarketCapNumber: marketCap,
      isNew: whitelistToken.isNew ? true : false,
    };

    return newItem;
  } catch (error) {
    return errorItem;
  }
}

// Obtain ALL (All Assets Array) Fast Data
export async function getDbFastArrayDataFetcher() {
  const requestUrl = `${shimmercapApiUrl}hero_fast_data`;
  try {
    const response = await axios.get(requestUrl);
    const responseArray = response.data;

    const heroFastApiArrayAddresses = new Set(
      responseArray.map((obj: {}) => Object.keys(obj)).flat()
    );

    let noOrderedAssetsStats: HeroItemStats[] = [];

    for (let i = 0; i < whitelistTokenData.length; i++) {
      const address = whitelistTokenData[i].Address;
      if (heroFastApiArrayAddresses.has(address)) {
        const itemData = responseArray.find(
          (entry: { hasOwnProperty: (arg0: string) => any }) =>
            entry.hasOwnProperty(address)
        );

        const price = Number(itemData[address].price.usd);
        const marketCap = Number(itemData[address].marketcap.usd);
        const circSupply = Number(itemData[address].circulating_supply);
        const oneHourChangeData = getPriceChangeString(
          itemData[address].price_change.usd_one_hour_change
        );

        const twoHoursChangeData = getPriceChangeString(
          itemData[address].price_change.usd_two_hours_change
        );

        const oneDayChangeData = getPriceChangeString(
          itemData[address].price_change.usd_one_day_change
        );
        const oneWeekChangeData = getPriceChangeString(
          itemData[address].price_change.usd_one_week_change
        );

        const newItem: HeroItemStats = {
          LogoUrl: whitelistTokenData[i].ImageURL,
          Name: whitelistTokenData[i].Name,
          Symbol: whitelistTokenData[i].Symbol,
          Price: `$ ${pricePrettier(price)}`,
          MarketCap: `$ ${formatNumber(marketCap)}`,
          CircSupply: `${formatNumber(circSupply)}`,
          PriceChange: {
            Hour: {
              ChangeString: oneHourChangeData[0],
              Color: oneHourChangeData[1],
            },
            TwoHours: {
              ChangeString: twoHoursChangeData[0],
              Color: twoHoursChangeData[1],
            },
            Day: {
              ChangeString: oneDayChangeData[0],
              Color: oneDayChangeData[1],
            },
            Week: {
              ChangeString: oneWeekChangeData[0],
              Color: oneWeekChangeData[1],
            },
          },
          MarketCapNumber: marketCap,
          isNew: whitelistTokenData[i].isNew ? true : false,
        };
        noOrderedAssetsStats.push(newItem);
      } else {
        // The Whitelist has an address not added in DB (in FastMode Database in this case)
        noOrderedAssetsStats.push(notFoundItem);
      }
    }

    return noOrderedAssetsStats;
  } catch (error) {
    console.log(error);
    return Array(whitelistTokenData.length).fill(errorItem);
  }
}

export async function getPriceRangeDbDataFetcher(
  assetAddress: string,
  daysRange: number,
  interval: number
) {
  const requestUrl = `${shimmercapApiUrl}asset_price_range_db?address=${assetAddress}&days=${daysRange}&interval=${interval}`;
  try {
    const apiResponse = await axios.get(requestUrl);

    return apiResponse.data;
  } catch (error) {
    return [];
  }
}
