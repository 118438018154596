import {
  Box,
  Image,
  HStack,
  Container,
  Text,
  GridItem,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { TbExternalLink } from "react-icons/tb";
import { FaBook } from "react-icons/fa";
import { explorerUrl, whitelistTokenData } from "../constants";
import { errorWhitelistToken } from "../types";
import { GetItemData } from "./core/ItemData";
import {
  addMarketsBlock,
  addOfficialLinksBlock,
  addSocialBlock,
} from "./partials/AssetPagePartials";
import PriceAssetChart from "./partials/PriceCharts";
import {
  backgroundGeneralColor,
  backgroundNavFooterColor,
  primaryColor,
  thirdColor,
} from "../customTheme";

export default function AssetPage() {
  const [isMobile] = useMediaQuery("(max-width: 870px)");
  const screenWidth = window.innerWidth;

  const customGridTemplateColumns =
    screenWidth > 590 && screenWidth < 870 ? "600px 1fr" : "380px 1fr";

  const gridTemplateAreas = isMobile
    ? `"header"
     "main-data"
     "graph"
     "general-data"
     "description"
     "pool-list"`
    : `"header header"
     "main-data graph"
     "general-data graph"
     "general-data description"
     "general-data pool-list"`;

  const { assetSymbol } = useParams<{ assetSymbol: string }>();

  const tokenWhitelistObject =
    whitelistTokenData.find((token) => token.Symbol === assetSymbol) ||
    errorWhitelistToken;

  const tokenDBObject = GetItemData(tokenWhitelistObject);

  return (
    <Box bg={backgroundGeneralColor}>
      <Container maxW="container.xl" centerContent>
        <Grid
          templateAreas={gridTemplateAreas}
          gridTemplateRows={"50px"}
          gridTemplateColumns={customGridTemplateColumns}
          h="auto"
          gap="1"
          color="blackAlpha.700"
          fontWeight="bold"
        >
          <GridItem pl="3" marginBlockStart="6" area={"header"}>
            <HStack spacing="14px" textColor={primaryColor}>
              <Text>{`Assets`}</Text>
              <Text>{`>`}</Text>
              <Text>{`${tokenWhitelistObject.Symbol}`}</Text>
            </HStack>
          </GridItem>

          <GridItem
            pl="6"
            py="6"
            bg={backgroundNavFooterColor}
            textColor={thirdColor}
            area={"main-data"}
          >
            <HStack spacing="13px">
              <Box>
                <Image
                  src={tokenWhitelistObject.ImageURL}
                  boxSize="2.8rem"
                  mr="20px"
                />
              </Box>
              <Box fontSize={"3xl"} fontWeight={"normal"}>
                {tokenWhitelistObject.Name}
              </Box>
              <Text fontSize={"xl"}>/</Text>
              <Box fontSize={"xl"} fontWeight={"bold"} marginEnd={5}>
                <i>{tokenWhitelistObject.Symbol}</i>
              </Box>
            </HStack>
            <HStack
              spacing={tokenDBObject.Price.length < 13 ? "8px" : "0"}
              pl={tokenDBObject.Price.length < 17 ? "12" : "0"}
            >
              <Box fontSize={tokenDBObject.Price.length < 11 ? "3xl" : "2xl"}>
                {tokenDBObject.Price}
              </Box>
              <Box pl="3"></Box>
              <Box
                fontSize={"l"}
                textColor={tokenDBObject.PriceChange.Day.Color}
              >
                {tokenDBObject.PriceChange.Day.ChangeString}
              </Box>
              <Box fontSize={"sm"}>
                {tokenDBObject.Price.length < 13 ? "(1d)" : ""}
              </Box>
            </HStack>
          </GridItem>

          <GridItem
            pl="7"
            py="6"
            bg={backgroundNavFooterColor}
            textColor={thirdColor}
            area={"general-data"}
          >
            <HStack>
              <Box fontSize={"lg"} fontWeight="normal">
                MarketCap
              </Box>
              <Box fontSize={"lg"} fontWeight="bold" ml="auto" marginEnd={"9"}>
                {`$ ` +
                  tokenDBObject.MarketCapNumber.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </Box>
            </HStack>
            <HStack>
              <Box fontSize={"lg"} fontWeight="normal">
                Circulating Supply
              </Box>
              <Box fontSize={"lg"} fontWeight="bold" ml="auto" marginEnd={"9"}>
                {tokenDBObject.CircSupply}
              </Box>
            </HStack>

            <HStack fontSize={"md"} marginBlockStart={"10"}>
              <Box fontWeight="normal">Contract:</Box>
              <Box fontWeight="thin">(ERC20)</Box>
            </HStack>
            <HStack alignItems="center">
              <Box fontSize={"13"} fontWeight="thin">
                {tokenWhitelistObject.Address ===
                "0x1111111111111111111111111111111111111111"
                  ? "-- Network Native Asset --"
                  : tokenWhitelistObject.Address}
              </Box>
              {tokenWhitelistObject.Address ===
              "0x1111111111111111111111111111111111111111" ? null : (
                <TbExternalLink
                  size={"22"}
                  color={"#000000"}
                  cursor={"pointer"}
                  onClick={() => {
                    window.open(
                      explorerUrl + `${tokenWhitelistObject.Address}`,
                      "_blank"
                    );
                  }}
                />
              )}
            </HStack>

            {addOfficialLinksBlock(tokenWhitelistObject.Details?.OfficialLinks)}

            {addSocialBlock(tokenWhitelistObject.Details?.SocialLinks)}
          </GridItem>

          <GridItem
            area={"graph"}
            display="flex"
            justifyContent="center"
            bg={backgroundGeneralColor}
          >
            {PriceAssetChart(tokenWhitelistObject.Address)}
          </GridItem>

          <GridItem
            pl="6"
            py="6"
            bg={backgroundNavFooterColor}
            textColor={thirdColor}
            area={"description"}
          >
            <HStack spacing="14px">
              <FaBook color={thirdColor} />
              <Box fontSize={"xl"} fontWeight={"bold"}>
                Token Description:
              </Box>
            </HStack>
            <Box
              fontSize={isMobile ? "sm" : "14"}
              textColor={"gray.800"}
              fontFamily={"monospace"}
              fontWeight={"thin"}
              marginTop={"3"}
              marginInlineStart={"8"}
              marginInlineEnd={"10"}
              dangerouslySetInnerHTML={{
                __html: tokenWhitelistObject.Details?.Description,
              }}
            ></Box>
          </GridItem>

          <GridItem
            pl="6"
            py="6"
            bg={backgroundNavFooterColor}
            area={"pool-list"}
          >
            {addMarketsBlock(
              tokenWhitelistObject.Name,
              tokenWhitelistObject.Symbol,
              tokenWhitelistObject.Details?.Markets
            )}
          </GridItem>
        </Grid>
      </Container>
      <Box py="2"></Box>
    </Box>
  );
}
