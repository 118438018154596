import {
  Text,
  Box,
  Image,
  OrderedList,
  ListItem,
  HStack,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";

import { extractPercentage } from "../../../core/utils";
import { HeroItemStats } from "../../../types";

export function GetTrendingRows(itemsData: HeroItemStats[]) {
  const [isMobile] = useMediaQuery("(max-width: 1025px)");

  // Its needed to make a copy because touch `itemsData` affect all the Hero
  const itemsDataCopy: HeroItemStats[] = JSON.parse(JSON.stringify(itemsData));

  // Sorted by 2hours greater gain
  const sortedArray = itemsDataCopy
    .sort((a, b) => {
      const changeA = extractPercentage(a.PriceChange.TwoHours.ChangeString);
      const changeB = extractPercentage(b.PriceChange.TwoHours.ChangeString);
      return changeB - changeA;
    })
    .slice(0, 5);

  // Filter array with elements < +2%
  let filteredArray: HeroItemStats[] = [];
  for (let i = 0; i < sortedArray.length; i++) {
    if (
      extractPercentage(sortedArray[i].PriceChange.TwoHours.ChangeString) >= 0.7
    )
      filteredArray.push(sortedArray[i]);
  }

  // Generating Rows
  let trendingRows: JSX.Element[] = [];
  for (let i = 0; i < filteredArray.length; i++) {
    trendingRows.push(TrendingRow(filteredArray[i], isMobile));
  }

  let bodyReturn: JSX.Element;

  if (filteredArray.length > 0) {
    bodyReturn = (
      <OrderedList
        p={isMobile ? 0 : 3}
        styleType={isMobile ? "none" : "decimal"}
      >
        {trendingRows}
      </OrderedList>
    );
  } else {
    bodyReturn =
      itemsData[0].Price == "" ? (
        <Box
          textAlign={"center"}
          paddingTop={isMobile ? "4" : "10"}
          paddingLeft={isMobile ? "5" : "90px"}
          paddingRight={isMobile ? "5" : "90px"}
        >
          <Text fontWeight={"thin"} fontSize={isMobile ? "15" : "23"}>
            Loading...
          </Text>
        </Box>
      ) : (
        <Box
          textAlign={"center"}
          paddingTop={isMobile ? "1" : "10"}
          paddingLeft={isMobile ? "5" : "90px"}
          paddingRight={isMobile ? "5" : "90px"}
        >
          <Text fontWeight={"thin"} fontSize={isMobile ? "21" : "28"}>
            {"No trends to report"}
          </Text>
          <Text fontSize={isMobile ? "35" : "50"}>{"☔"}</Text>
        </Box>
      );
  }

  return bodyReturn;
}

function TrendingRow(itemStats: HeroItemStats, isMobile: boolean) {
  return (
    <ListItem py={isMobile ? "0.4" : "2"}>
      <HStack>
        <Box>
          <Image
            src={String(itemStats.LogoUrl)}
            boxSize={isMobile ? "0.8rem" : "0.9rem"}
            objectFit="cover"
            marginStart={isMobile ? 0 : 1}
            marginEnd={isMobile ? 0 : 1}
          />
        </Box>
        <HStack spacing="1.5">
          <Box marginEnd={isMobile ? 0 : 1}>
            <Text
              fontWeight="semibold"
              fontSize={itemStats.Name.length > 15 ? "15.5" : "17.5"}
            >
              {isMobile ? "" : itemStats.Name}
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={isMobile ? "semibold" : "light"}
              fontSize={isMobile ? "15" : "small"}
            >
              {itemStats.Symbol}
            </Text>
          </Box>
        </HStack>
        <Spacer />
        <HStack spacing={"0.5"} marginEnd={isMobile ? "1" : "0"}>
          <Text
            fontWeight="semibold"
            fontSize={isMobile ? "11" : "normal"}
            textColor={"green.600"}
          >
            ▲
          </Text>
          <Text fontWeight="semibold" fontSize={isMobile ? "xs" : "small"}>
            {itemStats.PriceChange.TwoHours.ChangeString.replace("▲ ", "")}
          </Text>
        </HStack>
      </HStack>
    </ListItem>
  );
}
