import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Image,
  Divider,
  Link as ChakraLink,
  HStack,
  Center,
  ListItem,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { backgroundGeneralColor } from "../customTheme";
import { Highlight, themes } from "prism-react-renderer";

export default function MyPortfolioPage() {
  const [isMobile] = useMediaQuery("(max-width: 870px)");

  return (
    <Box bg={backgroundGeneralColor}>
      <Container maxW="container.xl">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            color={"gray.800"}
            fontWeight={"normal"}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            IotaCap <b>My Portfolio</b>
          </Heading>

          <Center>
            <Box
              background={"purple.100"}
              border={"4px"}
              p={isMobile ? "15px" : "55px"}
              rounded={"20"}
              maxWidth={"800px"}
            >
              <Text
                color={"gray.800"}
                maxW="700px"
                fontWeight={600}
                textAlign={"left"}
                fontSize={{ base: "md", sm: "lg", md: "2xl" }}
                lineHeight={"110%"}
                fontFamily={"monospace"}
              >
                My Portfolio is currently under development...
              </Text>
              <Text
                color={"gray.800"}
                maxW="700px"
                paddingTop={{ base: 5, sm: 8, md: 12 }}
                textAlign={"left"}
                fontSize={{ base: "l", sm: "xl", md: "2xl" }}
                lineHeight={"110%"}
                fontFamily={"monospace"}
              >
                - Users will be able to <i>Monitor</i> and <i>Track</i> their
                investments in the <b>IOTA Network</b> securely, personally, and
                privately.
              </Text>
              <Text
                color={"gray.800"}
                maxW="700px"
                paddingTop={{ base: 5, sm: 6, md: 9 }}
                textAlign={"left"}
                fontSize={{ base: "l", sm: "xl", md: "2xl" }}
                lineHeight={"110%"}
                fontFamily={"monospace"}
              >
                - <i>Daily earnings</i>, <i>Profit/loss % reports</i>.{" "}
                <i>Graphs/Charts</i> over Time and <i>Trends</i>. <i>Weekly</i>{" "}
                and <i>Monthly</i> returns. Important Values and Indices for
                both <i>Traders</i> and <i>Holders</i>.{" "}
                <i>User Trade Operations</i>
                always encrypted.
              </Text>
              <Text
                color={"gray.800"}
                maxW="700px"
                paddingTop={{ base: 5, sm: 6, md: 9 }}
                textAlign={"left"}
                fontSize={{ base: "l", sm: "xl", md: "2xl" }}
                lineHeight={"110%"}
                fontFamily={"monospace"}
              >
                - <b>User data is Encrypted in our Databases</b>, and only the
                user can decrypt it, so users are protected in the event of a
                data leak.
              </Text>
            </Box>
          </Center>
        </Stack>
      </Container>
    </Box>
  );
}

function CodeBlockJson(codeBlock: string, isMobile: boolean) {
  return (
    <Text
      textAlign={"left"}
      fontSize={isMobile ? "9" : "small"}
      sx={{
        WebkitTouchCallout: "none",
        WebkitUserSelect: "text",
        msUserSelect: "text",
        userSelect: "text",
      }}
    >
      <Highlight theme={themes.palenight} code={codeBlock} language="yaml">
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre style={style}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line })}>
                <span>{i + 1}</span>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </Text>
  );
}
