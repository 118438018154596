import {
  Box,
  Card,
  Heading,
  HStack,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ArticleItem } from "../../../types";

const cardsSwitchInterval = 3.5; // Switch interval in seconds (carrousel)

const maxLenghtContentText = 128;

export function GetUserfulArticlesCard(
  itemsData: ArticleItem[],
  isMobile: boolean
) {
  const cardPostsHeight = isMobile ? "156px" : "245px";

  const cardsTextInitMargin = isMobile ? 4 : 6;

  const smallTitleSize = isMobile ? "10" : "18";
  const longTitleSize = isMobile ? "13" : "24";

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % itemsData.length);
    }, cardsSwitchInterval * 1000); // Change card every 3 seconds

    return () => clearInterval(interval);
  }, [itemsData.length]);

  return (
    <Box
      position="relative"
      overflow="hidden"
      marginTop={cardsTextInitMargin - 2}
    >
      <Box
        display="flex"
        height={cardPostsHeight}
        width={`${itemsData.length * 100}%`}
        transform={`translateX(-${(activeIndex * 100) / itemsData.length}%)`}
        transition="transform 0.5s ease-in-out"
      >
        {itemsData.map((article, index) => (
          <Card
            key={index}
            flex="1"
            margin="0 1px"
            boxShadow={"lg"}
            onClick={() => {
              if (String(article.BannerUrl) !== "loader.gif")
                window.open(`${article.Link}`, "_blank");
            }}
            transition="transform 250ms"
            _hover={{
              transform: "scale(1.01)",
            }}
          >
            <Box borderTopRadius="md" overflow="hidden">
              <Image
                src={article.BannerUrl}
                width="100%"
                maxHeight="80px"
                objectFit="cover"
              />
            </Box>

            <Box p={isMobile ? 1.5 : 3}>
              <Heading
                fontSize={
                  article.Title.length > 25 ? smallTitleSize : longTitleSize
                }
              >
                {article.Title}
              </Heading>
              <Text
                color={"gray.500"}
                fontSize={isMobile ? "7" : "13"}
                whiteSpace="normal"
                paddingTop={isMobile ? "2px" : "3px"}
              >
                {article.Content.length > maxLenghtContentText
                  ? article.Content.slice(0, maxLenghtContentText)
                  : article.Content}
                {article.Content == "" ? "" : ".."}
                <b>{article.Content == "" ? "" : " See more..."}</b>
              </Text>
              <HStack
                mt={3}
                direction={"row"}
                spacing={isMobile ? 2 : 4}
                align={"center"}
              >
                <Image
                  src={article.AvatarUrl}
                  borderRadius="full"
                  boxSize={isMobile ? "24px" : "33px"}
                  objectFit="cover"
                />
                <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                  <Text fontWeight={600} fontSize={isMobile ? "9" : "13"}>
                    {article.Username}
                  </Text>
                  <Text color={"gray.500"} fontSize={isMobile ? "7" : "13"}>
                    {article.Date}
                  </Text>
                </Stack>
              </HStack>
            </Box>
          </Card>
        ))}
      </Box>
      <Box padding={isMobile ? "7px" : "10px"}></Box>
      <Box
        display="flex"
        justifyContent="center"
        position="absolute"
        bottom="0px"
        width="100%"
      >
        {itemsData.map((_, index) => (
          <Box
            key={index}
            width="6px"
            height="6px"
            borderRadius="50%"
            backgroundColor={index === activeIndex ? "black" : "gray.400"}
            margin="0 5px"
            cursor="pointer"
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </Box>
    </Box>
  );
}
