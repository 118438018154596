import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Image,
  Divider,
  Link as ChakraLink,
  HStack,
  Center,
  ListItem,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { backgroundGeneralColor } from "../customTheme";
import { Highlight, themes } from "prism-react-renderer";

export default function ApiServicePage() {
  const [isMobile] = useMediaQuery("(max-width: 870px)");

  return (
    <Box bg={backgroundGeneralColor}>
      <Container maxW="container.xl">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            color={"gray.800"}
            fontWeight={"normal"}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            IotaCap <b>API Service</b>
          </Heading>
          <Text
            fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
            color={"gray.800"}
            align={"left"}
            maxW="1000px"
            alignSelf={"center"}
          >
            Enhance your Webpage and Data Analysis with up-to-the-minute updated
            Prices, Historical Prices, Market Capitalizations, and other
            important Analytics of Assets/Tokens found on the IOTA Network.
          </Text>

          <Center>
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={isMobile ? "20px" : "70px"}
              align={"start"}
            >
              {CodeBlockJson(assetBasicStatsJson, isMobile)}
              {CodeBlockJson(assetHistoricalPricesJson, isMobile)}
            </Stack>
          </Center>
          <Divider borderColor={"gray.500"} />
          <Text
            fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
            color={"gray.800"}
            align={"left"}
            maxW="1000px"
            alignSelf={"center"}
          >
            Allow your users to stay informed about your Project with{" "}
            <b>real-time updated information</b> on your Project's Asset , or
            Tokens relevant to your Project's interests. <br />
            The <b>Basic Plan</b> is usually sufficient for these needs.
          </Text>
          <Stack alignContent={"center"} alignItems={"center"}>
            <Image
              width={isMobile ? "98%" : "70%"}
              src="/assetsPageImages/api/img1.png"
            ></Image>
            <Text mt={2} fontSize={isMobile ? "sm" : "md"} color="gray.600">
              Example: This <i>Memecoin Project</i> probably gets all the data
              related to it's token for it's website from a <i>Data API</i>.
            </Text>
          </Stack>

          <Text
            fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
            marginTop={"15px"}
            color={"gray.800"}
            align={"left"}
            maxW="1000px"
            alignSelf={"center"}
          >
            <b>Your project may require large amounts of Market Data.</b> <br />
            This is common in{" "}
            <i>DEXs, Lending Platforms, Decentralized Stablecoin Platforms</i>,
            and other <i>DeFi Platforms</i>, as they often need a third-party
            <i>OffChain Price</i> different from their own <i>OnChain Price</i>{" "}
            Platform. This allows users to check their balances with market
            values directly from your Project's Frontend. <br />
            The <b>PRO Plan</b> offers flexibility for high volumes of requests.
          </Text>
          <Stack alignContent={"center"} alignItems={"center"}>
            <Image
              width={isMobile ? "98%" : "70%"}
              src="/assetsPageImages/api/img2.png"
            ></Image>
            <Text mt={2} fontSize={isMobile ? "sm" : "md"} color="gray.600">
              Example: <i>Yahoo Finance</i> obtains a large amount of data from
              the <i>CoinMarketCap API</i> to provide a better service for its
              users.
            </Text>
          </Stack>

          <Text
            fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
            marginTop={"15px"}
            color={"gray.800"}
            align={"left"}
            maxW="1000px"
            alignSelf={"center"}
          >
            Stay in Sync with the IOTA Ecosystem with{" "}
            <b>
              <i>Telegram</i> Alerts.
            </b>
            <br />
            <b>
              Protect your investment from Drastic Price Changes that may affect
              your Portfolio
            </b>
            , receive Alerts about any exploit, strange movement or security
            vulnerabilities detected in any Token in the Ecosystem, and stay
            informed about high-volume movements on major <i>DEXs</i>.
          </Text>
          <Stack alignContent={"center"} alignItems={"center"}>
            <Image
              width={isMobile ? "98%" : "70%"}
              src="/assetsPageImages/api/img3.png"
            ></Image>
            <Text mt={2} fontSize={isMobile ? "sm" : "md"} color="gray.600">
              Example of a Custom User Alert sent by the{" "}
              <i>IotaCap Telegram Bot</i>.
            </Text>
          </Stack>
          <Divider borderColor={"gray.500"} />
          <Center>
            <Box
              background={"gray.300"}
              p={isMobile ? "15px" : "50px"}
              rounded={"20"}
              maxWidth={"800px"}
            >
              <Text
                color={"gray.800"}
                maxW="700px"
                fontWeight={600}
                textAlign={"left"}
                fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
                lineHeight={"110%"}
                fontFamily={"monospace"}
              >
                IotaCap <b>Available Plans</b>:
              </Text>
              <Text
                fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
                color={"gray.800"}
                paddingTop={"25px"}
                textAlign={"left"}
                maxW="1000px"
              >
                <UnorderedList spacing={"40px"}>
                  <ListItem>
                    <b>INVESTOR:</b>
                    <UnorderedList styleType="'- '">
                      <ListItem>
                        Drastic Price Change of up to 3 Tokens Alert (Telegram
                        Alert).
                      </ListItem>
                    </UnorderedList>
                    <i>Personal Use</i>
                  </ListItem>

                  <ListItem>
                    <b>BASIC:</b>
                    <UnorderedList styleType="'- '">
                      <ListItem>150 requests/hour.</ListItem>
                      <ListItem>Limited Market Data Endpoints.</ListItem>
                      <ListItem>1 month Historical Data.</ListItem>
                      <ListItem>
                        Drastic Price Change of up to 3 Tokens Alert (Telegram
                        Alert).
                      </ListItem>
                    </UnorderedList>
                    <i>Commercial Use</i>
                  </ListItem>

                  <ListItem>
                    <b>PRO:</b>
                    <UnorderedList styleType="'- '">
                      <ListItem>5K requests/hour.</ListItem>
                      <ListItem>All Market Data Endpoints.</ListItem>
                      <ListItem>Unlimited Historical Data.</ListItem>
                      <ListItem>Exclusive Instance (Node).</ListItem>
                      <ListItem>
                        All Telegram Alerts for all the Tokens.
                      </ListItem>
                    </UnorderedList>
                    <i>Commercial Use</i>
                  </ListItem>
                </UnorderedList>
              </Text>
            </Box>
          </Center>
        </Stack>
      </Container>
    </Box>
  );
}

function CodeBlockJson(codeBlock: string, isMobile: boolean) {
  return (
    <Text
      textAlign={"left"}
      fontSize={isMobile ? "9" : "small"}
      sx={{
        WebkitTouchCallout: "none",
        WebkitUserSelect: "text",
        msUserSelect: "text",
        userSelect: "text",
      }}
    >
      <Highlight theme={themes.palenight} code={codeBlock} language="yaml">
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre style={style}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line })}>
                <span>{i + 1}</span>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </Text>
  );
}

const assetBasicStatsJson = `  /// Asset Basic Stats Endpoint / json

  {
    "Vrom":{
       "address":"0x83ec9db168c03bb827dc48839f21430d819be649",  
       "symbol":"Vrom",
       "name":"Vrom Meme Token",
       "price":{
         "usd":0.0027659263916704,
        "iota":0,0159327557124938
      },
      "marketcap":{
         "usd":2765.9263916704,
         "iota":15927.4193548234
      },
      "circulating_supply":1000000,
      "price_change":{
         "usd_one_hour_change":-0.10332665654496642,
         "usd_two_hours_change":0.5251342907328659,
         "usd_one_day_change":1.8341659752509232,
         "usd_one_week_change":3.243803766836706,

      },
      "timestamp":1721402305
   }
 }`;

const assetHistoricalPricesJson = `  /// Asset Historical Prices Endpoint / json

  {
    "0x83ec9db168c03bb827dc48839f21430d819be649":[   
       {
          "price_usd":0.0027410604148702,
          "timestamp":1720924200
       },
       {
         "price_usd":0.0027757740577589,
         "timestamp":1720992600
      },
      {
         "price_usd":0.0028485375487425,
         "timestamp":1721061000
      },
      {
         "price_usd":0.0027141850774416,
         "timestamp":1721129400
      },
      {
         "price_usd":0.002620492118162,
         "timestamp":1721197800
      }
   ]
 }`;
