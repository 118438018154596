import {
  Box,
  Tr,
  Td,
  Text,
  Image,
  HStack,
  Stack,
  Table,
  Thead,
  Th,
  Tbody,
  useMediaQuery,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { HeroItemStats } from "../../types";
import { whitelistTokenData } from "../../constants";
import {
  backgroundGeneralColor,
  backgroundNavFooterColor,
  thirdColor,
} from "../../customTheme";
import { FaInfoCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { extractPrice } from "../../core/utils";

let bg_rb_black = "gray.300"; // Hover color Item
let bg_rb_gray = "gray.200"; // Hover color Names Row

export function GetGeneratedHeroTable(itemsData: HeroItemStats[]) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  let rowsBody = [];
  for (let i = 0; i < whitelistTokenData.length; i++) {
    rowsBody.push(ItemBody(i + 1, itemsData[i]));
  }

  return (
    <Stack>
      <Table
        variant="striped"
        background={backgroundNavFooterColor}
        colorScheme="blackAlpha"
        textColor={thirdColor}
        h="full"
      >
        <Thead>
          <Tr
            _hover={{
              bg: bg_rb_gray,
            }}
          >
            <Th paddingLeft={isMobile ? 5 : 12}>#</Th>
            <Th>Name</Th>
            <Th textAlign={"right"}>Price (in USD)</Th>
            <Th textAlign={"center"}>1h %</Th>
            <Th textAlign={"center"} paddingLeft={isMobile ? -2 : 0}>
              24h %
            </Th>
            <Th textAlign={"center"} paddingLeft={isMobile ? -2 : 0}>
              7d %
            </Th>
            <Th textAlign={"right"} paddingLeft={isMobile ? 1 : 0}>
              MarketCap
              {isMobile ? null : (
                <Tooltip
                  label={
                    "MarketCap Total Calculation includes the sum of the Token across both L1 and L2 (EVM) IOTA Layers.\nAt the moment, only IOTA has representation in L1."
                  }
                  aria-label="info"
                >
                  <span>
                    <Icon as={FaInfoCircle} ml={2} cursor="pointer" />
                  </span>
                </Tooltip>
              )}
            </Th>
            <Th
              paddingLeft={isMobile ? -5 : -20}
              paddingRight={isMobile ? 5 : 12}
              textAlign={"right"}
            >
              Circ Supply
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rowsBody}</Tbody>
      </Table>
      <Text
        background={backgroundGeneralColor}
        padding={"4"}
        py={{ base: 3, md: 3 }}
        h="70"
      ></Text>
    </Stack>
  );
}

function ItemBody(itemIndex: number, itemDataStruct: HeroItemStats) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  const [previousPrice, setPreviousPrice] = useState(itemDataStruct.Price);
  const [highlightPrice, setHighlightPrice] = useState(false);
  const [isPositiveChangeState, setIsPositiveChangeState] = useState(true);

  useEffect(() => {
    if (itemDataStruct.Price !== previousPrice && previousPrice !== "") {
      if (extractPrice(previousPrice) > extractPrice(itemDataStruct.Price))
        setIsPositiveChangeState(false);
      else setIsPositiveChangeState(true);
      setHighlightPrice(true);
      setPreviousPrice(itemDataStruct.Price);
      const timer = setTimeout(() => setHighlightPrice(false), 1100);
      return () => clearTimeout(timer);
    }
  }, [itemDataStruct.Price]);

  return (
    <Tr
      cursor="pointer"
      _hover={{
        bg: bg_rb_black,
      }}
      onClick={() => {
        if (String(itemDataStruct.LogoUrl) !== "loader.gif")
          window.location.href = `/asset/${itemDataStruct.Symbol}`;
      }}
    >
      <Td paddingLeft={isMobile ? 5 : 12}>{itemIndex}</Td>
      <Td paddingLeft={isMobile ? -4 : 0}>
        {GetAdaptedAssetName(itemDataStruct)}
      </Td>
      <Td
        textAlign={"right"}
        textColor={
          highlightPrice
            ? isPositiveChangeState
              ? "green.600"
              : "red.500"
            : "gray.900"
        }
        paddingLeft={isMobile ? -3 : -4}
        fontWeight={isMobile ? "medium" : "bold"}
        fontSize={isMobile ? "sm" : "md"}
        style={{
          transition: highlightPrice ? "none" : "color 2.6s ease-in-out",
        }}
      >
        {itemDataStruct.Price}
      </Td>

      <Td
        textColor={itemDataStruct.PriceChange.Hour.Color}
        textAlign={"center"}
        fontSize={isMobile ? "sm" : "md"}
      >
        {itemDataStruct.PriceChange.Hour.ChangeString}
      </Td>
      <Td
        textColor={itemDataStruct.PriceChange.Day.Color}
        paddingLeft={isMobile ? -2 : 0}
        textAlign={"center"}
        fontSize={isMobile ? "sm" : "md"}
      >
        {itemDataStruct.PriceChange.Day.ChangeString}
      </Td>
      <Td
        textColor={itemDataStruct.PriceChange.Week.Color}
        paddingLeft={isMobile ? -2 : 0}
        textAlign={"center"}
        fontSize={isMobile ? "sm" : "md"}
      >
        {itemDataStruct.PriceChange.Week.ChangeString}
      </Td>
      <Td
        textAlign={"right"}
        fontSize={isMobile ? "sm" : "md"}
        paddingLeft={isMobile ? -5 : 0}
      >
        {itemDataStruct.MarketCap}
      </Td>
      <Td
        paddingLeft={isMobile ? -5 : -20}
        paddingRight={isMobile ? 5 : 12}
        textAlign={isMobile ? "center" : "right"}
        fontSize={isMobile ? "sm" : "md"}
      >
        {itemDataStruct.CircSupply}
      </Td>
    </Tr>
  );
}

function AddIsNewIcon(isNew: boolean | undefined) {
  if (isNew) {
    return (
      <Box marginStart={1}>
        <Image src={"new-icon-orange.svg"} boxSize="2rem" mr="12px" />
      </Box>
    );
  }
}

function GetAdaptedAssetName(itemsData: HeroItemStats) {
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  if (!isMobile)
    return (
      <HStack spacing="13px">
        <Image
          src={String(itemsData.LogoUrl)}
          boxSize="2rem"
          objectFit="cover"
        />
        <Box
          fontSize={"lg"}
          fontWeight={"semibold"}
          marginStart={3}
          marginEnd={1}
        >
          {itemsData.Name}
        </Box>
        <Text fontSize={"xs"}>/</Text>
        <Box fontSize={"sm"} fontWeight={"normal"} marginStart={1}>
          <i> {itemsData.Symbol}</i>
        </Box>
        {AddIsNewIcon(itemsData.isNew)}
      </HStack>
    );
  else {
    return (
      <HStack spacing="0px">
        <Image
          src={String(itemsData.LogoUrl)}
          boxSize="1.6rem"
          objectFit="cover"
        />
        <Box fontSize={"md"} fontWeight={"semibold"} marginStart={3}>
          {itemsData.Symbol}
        </Box>
      </HStack>
    );
  }
}
